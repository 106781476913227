<form [formGroup]="form" class="row">
    <div class="form-group d-elx col-xs-12 col-md-6">
        <label class="col-md-4 col-form-label text-lg-end" [ngClass]="required? 'required':''">{{ labelStart  }}</label>
        <div class="col-md-8">
            <p-calendar placeholder="請選取開始日期" dateFormat="yy-mm-dd" yearRange="1911:2100" [locale]="tw"
                (onSelect)="onSelectStart($event , form , ctrlStart)" [minDate]="minStartDate" [readonlyInput]="true"
                [formControlName]="ctrlStart" [disabled]="disabled" [showTime]="showTime" [maxDate]="maxStartDate"
                [dataType]="'string'" [showIcon]="true" [showButtonBar]="true" [monthNavigator]="true"
                [yearNavigator]="true" todayButtonStyleClass="p-button-primary" clearButtonStyleClass="p-button-danger">
            </p-calendar>
        </div>

    </div>
    <div class="form-group d-elx col-xs-12 col-md-6">
        <label class="col-md-4 col-form-label text-lg-end" [ngClass]="required? 'required':''">{{ labelEnd }}</label>
        <div class="col-md-8">
            <p-calendar placeholder="請選取結束日期" dateFormat="yy-mm-dd" yearRange="1911:2100" [readonlyInput]="true"
                [disabled]="disabled" [showTime]="showTime" [locale]="tw"
                (onSelect)="onSelectEnd($event , form , ctrlEnd)" [formControlName]="ctrlEnd" [minDate]="minEndDate"
                [maxDate]="maxEndDate" [dataType]="'string'" [showIcon]="true" [showButtonBar]="true"
                [monthNavigator]="true" [yearNavigator]="true">
            </p-calendar>
        </div>
    </div>
</form>
<!-- <div class="form-group d-flex col-xs-12 col-md-6">
    <label class="col-md-4 col-form-label text-lg-end">攝影機</label>
    <div class="col-md-8">
        <p-dropdown placeholder="請選擇攝影機" [filter]="true" [emptyFilterMessage]="'查無符合的項目'" formControlName="CCTVID"
            [options]="" [showClear]="false">
        </p-dropdown>
    </div>
</div> -->