import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '../../i18n/i18n.module';
import { BigBreadcrumbsComponent } from './big-breadcrumbs.component';
import { MinifyMenuComponent } from './minify-menu.component';
import { NavigationComponent } from './navigation.component';
import { SmartMenuDirective } from './smart-menu.directive';

import { RouterModule } from '@angular/router';

// primeng
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';

import { CollapseMenuComponent } from './collapse-menu/collapse-menu.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		I18nModule,
		FormsModule,
		ReactiveFormsModule,
		// PrimeNg
		BreadcrumbModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		DropdownModule,
		DialogModule,
		BigBreadcrumbsComponent,
		MinifyMenuComponent,
		NavigationComponent,
		SmartMenuDirective,
		CollapseMenuComponent,
	],
	exports: [
		BigBreadcrumbsComponent,
		MinifyMenuComponent,
		NavigationComponent,
		CollapseMenuComponent,
		SmartMenuDirective,
		// PrimeNg
		BreadcrumbModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		DropdownModule,
		DialogModule,
	],
})
export class NavigationModule {}
