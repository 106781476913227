import { ChangeDetectorRef, Component, Input, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import * as moment from "moment";
import { MessageService } from 'primeng/api';
import { CalendarModule } from "primeng/calendar";
import { NgClass } from "@angular/common";

@Component({
    selector: "sa-datepicker-minmax",
    templateUrl: "./datepicker-minmax.component.html",
    styleUrls: ["./datepicker-minmax.component.scss"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, CalendarModule]
})
export class DatepickerMinmaxComponent {
  @Input() ctrlStart: string;
  @Input() ctrlEnd: string;
  @Input() form: FormGroup;
  /** label顯示名稱 */
  @Input() labelStart = '開始時間';
  @Input() labelEnd = '結束時間';
  /** 是否為必填 */
  @Input() required = false;
  /** 時間限制 */
  @Input() minStartDate: Date = null;
  @Input() maxStartDate: Date = null;
  @Input() minEndDate: Date = null;
  @Input() maxEndDate: Date = null;
  /** 時間 */
  @Input() showTime = false;
  /** 控制使用者是否可選取時間 */
  @Input() disabled = false;
  /** 時長限制 */
  @Input() dateLimit: number = null;

  @Input() invalidStyleStart: string;
  @Input() invalidStyleEnd: string;

  tw: any;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.tw = {
      firstDayOfWeek: 0,
      dayNames: ['日', '一', '二', '三', '四', '五', '六'],
      dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
      dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
      monthNames: [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月',
      ],
      monthNamesShort: [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月',
      ],
      today: '當日',
      clear: '清除',
      dateFormat: 'mm-dd-yy',
      weekHeader: 'Wk',
    };
  }

  // FIXME:如何把值傳出去 跟 傳進來
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dateLimit &&
      changes.dateLimit.currentValue &&
      changes.dateLimit.previousValue
    ) {
      this.dateLimit = changes.dateLimit.currentValue;
    }

    if (
      changes.form &&
      changes.form.currentValue &&
      !changes.form.previousValue
    ) {
      const minEndDate = changes.form.currentValue.controls[changes.ctrlStart.currentValue].value
      this.minEndDate = new Date(minEndDate)
    }

  }


  /** 選取開始時間 */
  onSelectStart(event, formGroup: FormGroup, formControlName) {
    const d = new Date(Date.parse(event));
    const patchValue: object = {};

    patchValue[formControlName] = moment(d)
      .startOf("date")
      .format("YYYY-MM-DD HH:mm:ss");
    formGroup.patchValue(patchValue);

    /** 設定最小結束時間 */
    this.minEndDate = new Date(event);
    console.log('event:', event)
    console.log('this.minEndDate:', this.minEndDate)

    this.minEndDate.setMonth(new Date(Date.parse(event)).getMonth());
    this.minEndDate.setFullYear(new Date(Date.parse(event)).getFullYear());
    console.log('this.minEndDate:', this.minEndDate)
    console.log('this.maxEndDate:', this.maxEndDate)

    /** 設定最大結束時間 */
    if (this.dateLimit) {
      const today = moment()
      const end = moment(new Date(event)).add(this.dateLimit, 'day')

      if (end.diff(today, 'days') < 0) {
        /** 最大值不超過當日 */
        this.maxEndDate = end.toDate();
      } else {
        /** 最大值超過當日 */
        this.maxEndDate = today.toDate()
      }
    }

  }

  /** 選取結束時間 */
  onSelectEnd(event, formGroup: FormGroup, formControlName) {
    const d = new Date(Date.parse(event));
    const patchValue: object = {};
    patchValue[formControlName] = moment(d)
      .endOf("date")
      .format("YYYY-MM-DD HH:mm:ss");
    formGroup.patchValue(patchValue);
  }

  /** 檢查時間是否大於 幾天 (傳入 moment 格式 ) */
  checkLargDays(startTime, endTime, compareDays) {
    const start = startTime;
    const end = endTime;
    const diffResault = end.diff(start, 'days');
    console.log('檢查時間是否大於 diffResault:', diffResault);
    if (+diffResault <= +compareDays) {
      return true;
    }
    if (+diffResault > +compareDays) {
      return false;
    }
  }

  /** 清除時間 */
  clearTime() {
    this.form.controls[this.ctrlStart].patchValue('');
    this.form.controls[this.ctrlEnd].patchValue('');
  }
}
