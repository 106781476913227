import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { config } from "@app/core/app.config";
import { HttpClientService } from "@app/core/services/HttpClientService";

@Injectable({
    providedIn: "root",
})
export class LogAuditService {

    constructor(private httpService: HttpClientService) { }

    /** 取得日誌查詢結果 */
    public getLogAudit(dataSrc: any): Observable<any> {
        return this.httpService.httpPost(
            `${config.LogAuditUrl}/GetLogAudit`,
            dataSrc
        );
    }

    /** 取得日誌查詢詳細資料 */
    public getLogAuditDetails(dataSrc: any): Observable<any> {
        return this.httpService.httpPost(
            `${config.LogAuditUrl}/GetLogAuditDetails`,
            dataSrc
        );
    }

}
