<div class="demo" [class.activate]="isActivated">
	<span id="demo-setting" (click)="onToggle()">
		<em class="fa fa-cog txt-color-blueDark"></em>
	</span>

	<form>
		<!-- INFO:文字大小 加減 -->
		<div class="config-scale">
			<button
				class="me-3"
				pButton
				pRipple
				type="button"
				icon="pi pi-minus"
				title="Add minus"
				(click)="decrementScale()"
			></button>
			<button
				pButton
				pRipple
				type="button"
				icon="pi pi-plus"
				title="Add Column"
				(click)="incrementScale()"
			></button>
		</div>
		<!-- INFO:文字大小 加減 -->

		<legend class="no-padding margin-bottom-10">Layout Options</legend>
		<section>
			<label>
				<input
					type="checkbox"
					(click)="onFixedHeader()"
					[(ngModel)]="store.fixedHeader"
					name="fixedHeader"
					class="checkbox style-0"
				/>
				<span>Fixed Header</span>
			</label>
			<label>
				<input
					type="checkbox"
					(click)="onFixedNavigation()"
					[(ngModel)]="store.fixedNavigation"
					name="fixedNavigation"
					class="checkbox style-0"
				/>
				<span>Fixed Navigation</span>
			</label>
			<label>
				<input
					type="checkbox"
					(click)="onFixedRibbon()"
					[(ngModel)]="store.fixedRibbon"
					name="fixedRibbon"
					class="checkbox style-0"
				/>
				<span>Fixed Ribbon</span>
			</label>
			<label>
				<input
					type="checkbox"
					(click)="onFixedPageFooter()"
					[(ngModel)]="store.fixedPageFooter"
					name="fixedPageFooter"
					class="checkbox style-0"
				/>
				<span>Fixed Footer</span>
			</label>
			<label>
				<input
					type="checkbox"
					(click)="onInsideContainer()"
					[(ngModel)]="store.insideContainer"
					name="insideContainer"
					class="checkbox style-0"
				/>
				<span>
					Inside
					<strong>.container</strong>
				</span>
			</label>
			<label>
				<input
					type="checkbox"
					(click)="onRtl()"
					[(ngModel)]="store.rtl"
					name="rtl"
					class="checkbox style-0"
				/>
				<span>RTL</span>
			</label>
			<label>
				<input
					type="checkbox"
					(click)="onMenuOnTop()"
					[(ngModel)]="store.menuOnTop"
					name="menuOnTop"
					class="checkbox style-0"
				/>
				<span>
					Menu on
					<strong>top</strong>
				</span>
			</label>
			<label>
				<input
					type="checkbox"
					(click)="onColorblindFriendly()"
					[(ngModel)]="store.colorblindFriendly"
					name="colorblindFriendly"
					class="checkbox style-0"
				/>
				<span>
					For Colorblind
					<div class="font-xs text-right">(experimental)</div>
				</span>
			</label>
			<span id="smart-bgimages"></span>
		</section>
		<section>
			<h6 class="margin-top-10 semi-bold margin-bottom-5">
				Clear Localstorage
			</h6>
			<a
				(click)="factoryReset()"
				class="btn btn-xs btn-block btn-primary"
				id="reset-smart-widget"
			>
				<em class="fa fa-refresh"></em>
				Factory Reset
			</a>
		</section>

		<h6 class="margin-top-10 semi-bold margin-bottom-5">
			SmartAdmin Skins
		</h6>

		<section id="smart-styles">
			<a
				*ngFor="let skin of store.skins"
				(click)="onSmartSkin(skin)"
				[class]="skin.skinBtnClass"
				[ngStyle]="skin.style"
			>
				<em
					*ngIf="skin.name == store.smartSkin"
					class="fa fa-check fa-fw"
				></em>
				{{ skin.label }}
				<sup *ngIf="skin.beta">beta</sup>
			</a>
		</section>
	</form>
</div>
