<div id="shortcut" [@shortcutState]="state">
	<ul>
		<li>
			<a
				(click)="shortcutTo(['outlook'])"
				class="jarvismetro-tile big-cubes bg-color-blue"
			>
				<span class="iconbox">
					<em class="fa fa-envelope fa-4x"></em>
					<span>
						Mail
						<span class="label pull-right bg-color-darken">14</span>
					</span>
				</span>
			</a>
		</li>
		<li>
			<a
				(click)="shortcutTo(['calendar'])"
				class="jarvismetro-tile big-cubes bg-color-orangeDark"
			>
				<span class="iconbox">
					<em class="fa fa-calendar fa-4x"></em>
					<span>Calendar</span>
				</span>
			</a>
		</li>
		<li>
			<a
				(click)="shortcutTo(['maps'])"
				class="jarvismetro-tile big-cubes bg-color-purple"
			>
				<span class="iconbox">
					<em class="fa fa-map-marker fa-4x"></em>
					<span>Maps</span>
				</span>
			</a>
		</li>
		<li>
			<a
				(click)="shortcutTo(['miscellaneous/invoice'])"
				class="jarvismetro-tile big-cubes bg-color-blueDark"
			>
				<span class="iconbox">
					<em class="fa fa-book fa-4x"></em>
					<span>
						Invoice
						<span class="label pull-right bg-color-darken">99</span>
					</span>
				</span>
			</a>
		</li>
		<li>
			<a
				(click)="shortcutTo(['app-views/gallery'])"
				class="jarvismetro-tile big-cubes bg-color-greenLight"
			>
				<span class="iconbox">
					<em class="fa fa-picture-o fa-4x"></em>
					<span>Gallery</span>
				</span>
			</a>
		</li>
		<li>
			<a
				(click)="shortcutTo(['app-views/profile'])"
				class="jarvismetro-tile big-cubes selected bg-color-pinkDark"
			>
				<span class="iconbox">
					<em class="fa fa-user fa-4x"></em>
					<span>My Profile</span>
				</span>
			</a>
		</li>
	</ul>
</div>
