// 【一般及第三方匯入】
// 1.一般
import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// 2.Bootstrap
// import { BootstrapModule } from '@app/shared/bootstrap.module';

// 3.MATERIAL ===================================================
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Navigation
import { MatSidenavModule } from '@angular/material/sidenav';
// Material Layout
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
// Material Buttons & Indicators
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// Material Popups & Modals
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
// Material Data tables
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';

// 4.DatetimePicker===================================================
// import { MAT_DATE_LOCALE } from '@angular/material/core';

// 5.PrimeNG===================================================
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StepsModule } from 'primeng/steps';
import { AccordionModule } from 'primeng/accordion';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PickListModule } from 'primeng/picklist';
import { MegaMenuModule } from 'primeng/megamenu';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel';
import { RippleModule } from 'primeng/ripple';
import { PaginatorModule } from 'primeng/paginator';
import { BlockUIModule } from 'primeng/blockui';
import { ChipsModule } from 'primeng/chips';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { TreeModule } from 'primeng/tree';
import { PasswordModule } from 'primeng/password';
import { EditorModule } from 'primeng/editor';
// import { FileUploadModule } from 'primeng/fileupload';
import { BadgeModule } from 'primeng/badge';

// ng2-file-upload
// import { FileUploadModule } from 'ng2-file-upload';
@Injectable()
export class DefaultIntl  {
	/** A label for the cancel button */
	cancelBtnLabel = '取消';
	/** A label for the set button */
	setBtnLabel = '確定';
}

@NgModule({
	declarations: [],
	providers: [
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,

		// Bootstrap
		// BootstrapModule,

		// MATERIAL
		MatCheckboxModule,
		MatCardModule,
		MatInputModule,
		MatGridListModule,
		MatButtonToggleModule,
		MatButtonModule,
		MatDialogModule,
		MatSortModule,
		MatTableModule,
		MatPaginatorModule,
		MatIconModule,
		MatFormFieldModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatRadioModule,
		MatSidenavModule,
		MatTabsModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatListModule,
		MatChipsModule,
		MatStepperModule,
		MatProgressSpinnerModule,
		MatBadgeModule,
		MatProgressBarModule,
		MatTooltipModule,

		// DatetimePicker
		// NgxMatDatetimePickerModule,
		// NgxMatTimepickerModule,
		// NgxMatNativeDateModule,
		// Owl-DatetimePicker

		// PrimeNG
		TreeTableModule,
		TableModule,
		ToastModule,
		CalendarModule,
		SliderModule,
		MultiSelectModule,
		ContextMenuModule,
		DialogModule,
		DropdownModule,
		ProgressBarModule,
		InputTextModule,
		InputTextareaModule,
		StepsModule,
		AccordionModule,
		RatingModule,
		RadioButtonModule,
		ConfirmDialogModule,
		DynamicDialogModule,
		InputNumberModule,
		SelectButtonModule,
		PickListModule,
		MegaMenuModule,
		CheckboxModule,
		PanelModule,
		PaginatorModule,
		BlockUIModule,
		ChipsModule,
		ProgressSpinnerModule,
		InputMaskModule,
		BreadcrumbModule,
		RippleModule,
		ButtonModule,
		TooltipModule,
		TreeModule,
		PasswordModule,
		EditorModule,
		BadgeModule,

		TabViewModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,

		// Bootstrap
		// BootstrapModule,

		// MATERIAL
		MatCheckboxModule,
		MatCardModule,
		MatInputModule,
		MatGridListModule,
		MatButtonToggleModule,
		MatButtonModule,
		MatDialogModule,
		MatSortModule,
		MatTableModule,
		MatPaginatorModule,
		MatIconModule,
		MatFormFieldModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatRadioModule,
		MatSidenavModule,
		MatTabsModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatListModule,
		MatChipsModule,
		MatStepperModule,
		MatProgressSpinnerModule,
		MatBadgeModule,
		MatProgressBarModule,
		MatTooltipModule,

		// DatetimePicker
		// NgxMatDatetimePickerModule,
		// NgxMatTimepickerModule,
		// NgxMatNativeDateModule,
		// Owl-DatetimePicker

		// PrimeNG
		TreeTableModule,
		TableModule,
		ToastModule,
		CalendarModule,
		SliderModule,
		MultiSelectModule,
		ContextMenuModule,
		DialogModule,
		DropdownModule,
		ProgressBarModule,
		InputTextModule,
		InputTextareaModule,
		StepsModule,
		AccordionModule,
		RatingModule,
		RadioButtonModule,
		ConfirmDialogModule,
		DynamicDialogModule,
		InputNumberModule,
		SelectButtonModule,
		PickListModule,
		MegaMenuModule,
		CheckboxModule,
		PanelModule,
		PaginatorModule,
		BlockUIModule,
		ChipsModule,
		ProgressSpinnerModule,
		InputMaskModule,
		BreadcrumbModule,
		RippleModule,
		ButtonModule,
		TooltipModule,
		TreeModule,
		PasswordModule,
		EditorModule,
		BadgeModule,


		TabViewModule,
	],
})
export class GeneralAndThirdPartyModule { }
