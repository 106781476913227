<!-- RIBBON -->
<div id="ribbon">
	<ng-template #tipContent>
		<em class="text-warning fa fa-warning"></em>
		Warning! This will reset all your widget settings.
	</ng-template>

	<span class="ribbon-button-alignment">
		<span
			id="refresh"
			class="btn btn-ribbon"
			(click)="resetWidgets()"
			placement="bottom"
		>
			<em class="fa fa-refresh"></em>
		</span>
	</span>
	<sa-route-breadcrumbs></sa-route-breadcrumbs>
</div>
