import { JsonApiService } from './json-api.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/internal/operators';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { config } from '@app/core/app.config';
import { SelectItem } from 'primeng/api';
import { TemplateService } from './template.service';
import { TrainingService } from './training.service';
import { RoleGroupService } from './rolegroup.service';
import { ReportService } from './report.service';
@Injectable({
	providedIn: 'root',
})
export class SyscodeService {
	constructor(
		private httpService: HttpClientService,
		private jsonApiService: JsonApiService,
		private reportService: ReportService,
		private templateService: TemplateService,
		private trainingService: TrainingService,
		private roleGroupService: RoleGroupService,
	) {}

	/** true/false */
	_Boolean$ = new BehaviorSubject<SelectItem[]>(null);
	/** 啟用/停用 */
	_Check$ = new BehaviorSubject<SelectItem[]>(null);
	/** 演練任務狀態 */
	_CaseStatus$ = new BehaviorSubject<SelectItem[]>(null);
	/** 角色 */
	_Role$ = new BehaviorSubject<SelectItem[]>(null);
	/** 部室 */
	_Dept$ = new BehaviorSubject<SelectItem[]>(null);
	/** 部室統計用 */
	_DeptDDL$ = new BehaviorSubject<SelectItem[]>(null);
	/** 職級 */
	_Grade$ = new BehaviorSubject<SelectItem[]>(null);
	/** 問題類型 */
	_ProblemType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 問題狀態 */
	_ProcessStatus$ = new BehaviorSubject<SelectItem[]>(null);
	/** 代碼類型 */
	_SysType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 範本類型 */
	_TemplateType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 教育訓練範本類型(不包含手動設定) */
	_TrainingType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 教育訓練範本類型(所有範本) */
	_AllTrainingType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 演練項目 */
	_MailTaskType$ = new BehaviorSubject<SelectItem[]>(null);
	/** 可編輯的代碼類型 */
	_EditSysType$ = new BehaviorSubject<SelectItem[]>(null);
	// TODO:未使用
	/** 視覺化案件列表 */
	CaseList$ = new BehaviorSubject<SelectItem[]>(null);
	/** 視覺化節點類型 */
	NodeType$ = new BehaviorSubject<SelectItem[]>(null);

	// INFO:API syscode START--------------------------------------------------
	Boolean$ = this.jsonApiService.fetch('/syscode/boolean.json').pipe(
		// 模擬後端 API 串接回覆讓 getError 去接
		// map(res => this.errorCatchService.getError(res, 'Boolean')),
		map(res => res.body),
		catchError(x => {
			console.log('x:', x);
			// this.errorCatchService.isCatchErrCode(x, 'Boolean');
			return of();
		}),
		shareReplay(1),
	);
	Check$ = this.jsonApiService.fetch('/syscode/check.json').pipe(
		// 模擬後端 API 串接回覆讓 getError 去接
		// map(res => this.errorCatchService.getError(res, 'Boolean')),
		map(res => res.body),
		catchError(x => {
			console.log('x:', x);
			// this.errorCatchService.isCatchErrCode(x, 'Boolean');
			return of();
		}),
		shareReplay(1),
	);

	/** 取得演練任務狀態 */
	CaseStatus$ = this._CaseStatus$.pipe(
		switchMap(() => this.getSysCodeByType({ Type: 'MStatus' })),
		map(res =>
			res.body.filter((el: any) => !el.sts && el.label !== '暫存'),
		),
		shareReplay(1),
	);

	/** 取得角色 */
	Role$ = this._Role$.pipe(
		switchMap(() => this.roleGroupService.getRoleGroupList()),
		map(res =>
			res.body.map(el => ({ ...el, value: el.Id, label: el.CName })),
		),
		shareReplay(1),
	);

	/** 取得部門 */
	Dept$ = this._Dept$.pipe(
		switchMap(() => this.getSysCodeByType({ Type: 'Dept' })),
		map(res => res.body.filter((el: any) => !el.sts)),
		shareReplay(1),
	);

	/** 取得統計用部門 */
	DeptDDL$ = this._DeptDDL$.pipe(
		switchMap(() => this.reportService.getDeptDDL()),
		map(res => res.body.map(el => ({ ...el }))),
		shareReplay(1),
	);

	/** 取得職級 */
	Grade$ = this._Grade$.pipe(
		switchMap(() => this.getSysCodeByType({ Type: 'Grade' })),
		map(res => res.body.filter((el: any) => !el.sts)),
		shareReplay(1),
	);

	/** 取得問題回報類型 */
	ProblemType$ = this.jsonApiService.fetch('/syscode/problemtype.json').pipe(
		// 模擬後端 API 串接回覆讓 getError 去接
		// map(res => this.errorCatchService.getError(res, 'Boolean')),
		map(res => res.body),
		catchError(x => {
			console.log('x:', x);
			// this.errorCatchService.isCatchErrCode(x, 'Boolean');
			return of();
		}),
		shareReplay(1),
	);

	/** 取得問題狀態 */
	ProcessStatus$ = this.jsonApiService
		.fetch('/syscode/processstatus.json')
		.pipe(
			// 模擬後端 API 串接回覆讓 getError 去接
			// map(res => this.errorCatchService.getError(res, 'Boolean')),
			map(res => res.body),
			catchError(x => {
				console.log('x:', x);
				// this.errorCatchService.isCatchErrCode(x, 'Boolean');
				return of();
			}),
			shareReplay(1),
		);

	/** 取得代碼類型 */
	SysType$ = this._SysType$.pipe(
		switchMap(() => this.getSysType()),
		map(res => res.body.map(el => ({ ...el }))),
		shareReplay(1),
	);

	/** 取得範本類型 */
	TemplateType$ = this._TemplateType$.pipe(
		switchMap(() => this.templateService.getTemplateType({})),
		map(res =>
			res.body.map(el => ({
				...el,
				value: el.TemplateTypeID,
				label: el.TemplateType,
			})),
		),
		shareReplay(1),
	);

	/** 教育訓練範本類型(不包含手動設定) */
	TrainingType$ = this._TrainingType$.pipe(
		switchMap(() => this.trainingService.getTrainingDropdownList({})),
		map(res =>
			res.body.map(el => ({
				...el,
				value: el.T_ID,
				label: el.TrainingTitle,
			})),
		),
		shareReplay(1),
	);

	/** 教育訓練範本類型(所有範本) */
	AllTrainingType$ = this._AllTrainingType$.pipe(
		switchMap(() => this.trainingService.getTrainingAllDropdownList({})),
		map(res =>
			res.body.map(el => ({
				...el,
				value: el.T_ID,
				label: el.TrainingTitle,
			})),
		),
		shareReplay(1),
	);

	/** 演練項目 */
	MailTaskType$ = this._MailTaskType$.pipe(
		switchMap(() => this.trainingService.getMailTaskList({})),
		map(res =>
			res.body.map(el => ({
				...el,
				value: el.M_ID,
				label: el.MailTitle,
			})),
		),
		shareReplay(1),
	);

	/** 可編輯的代碼類型 */
	EditSysType$ = this._EditSysType$.pipe(
		switchMap(() => this.getEditSysType()),
		map(res => res.body.map(el => ({ ...el }))),
		shareReplay(1),
	);

	// INFO:更新shareReplay cach START--------------------------------------------------
	syscodeUpdate = {
		Boolean: _this => _this._Boolean$.next(), // Boolean
		Check: _this => _this._Check$.next(), // Check
		CaseStatus: _this => _this._CaseStatus$.next(), // CaseStatus
		Role: _this => _this._Role$.next(), // Role
		Dept: _this => _this._Dept$.next(), // Dept
		DeptDDL: _this => _this._DeptDDL$.next(), // DeptDDL
		Grade: _this => _this._Grade$.next(), // Grade
		ProblemType: _this => _this._ProblemType$.next(), // ProblemType
		ProcessStatus: _this => _this._ProcessStatus$.next(), // ProcessStatus
		SysType: _this => _this._SysType$.next(), // SysType
		TemplateType: _this => _this._TemplateType$.next(), // TemplateType
		TrainingType: _this => _this._TrainingType$.next(), // TrainingType
		AllTrainingType: _this => _this._AllTrainingType$.next(), // AllTrainingType
		MailTaskType: _this => _this._MailTaskType$.next(), // MailTaskType
		EditSysType: _this => _this._EditSysType$.next(), // EditSysType
	};

	// INFO:功能型 function END-----------------------------------------------------
	initSyscode() {
		Object.values(this.syscodeUpdate).forEach(updateFunction =>
			typeof updateFunction === 'function' ? updateFunction(this) : null,
		);
		// this.getCaseStatusSyscode();
		// this.getRoleSyscode();
		// this.getDeptSyscode();
		// this.getDeptDDLSyscode();
		// this.getGradeSyscode();
		// this.getProblemTypeSyscode();
		// this.getProcessStatusSyscode();
		// this.getSysTypeSyscode();
		// this.getTemplateTypeSyscode();
		// this.getTrainingTypeSyscode();
		// this.getAllTrainingTypeSyscode();
		// this.getMailTaskLisSysCode();
		this.getCaseListSyscode();
		this.getNodeTypeSyscode();
		// this.getEditSysTypeSyscode();
	}

	// getMailTaskLisSysCode() {
	// 	this.trainingService.getMailTaskLis({}).subscribe(res => {
	// 		let tmp = [];
	// 		res.body.map(el => {
	// 			tmp.push({
	// 				value: el.M_ID,
	// 				label: el.MailTitle,
	// 			});
	// 		});
	// 		this.MailTaskType$.next(tmp);
	// 	});
	// }

	getCaseListSyscode() {
		this.jsonApiService.fetch('/syscode/caselist.json').subscribe(res => {
			this.CaseList$.next(res);
		});
	}

	getNodeTypeSyscode() {
		this.jsonApiService.fetch('/syscode/nodetype.json').subscribe(res => {
			this.NodeType$.next(res);
		});
	}

	// getSysTypeSyscode() {
	// 	this.getSysType().subscribe(res => this.SysType$.next(res.body));
	// }
	// getEditSysTypeSyscode() {
	// 	this.getEditSysType().subscribe(res =>
	// 		this.EditSysType$.next(res.body),
	// 	);
	// }

	/** 取得單層系統代碼 */
	getSysCodeByType(dataSrc) {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/GetSysCodeByType`,
			dataSrc,
		);
	}

	/** 取得系統代碼類型 */
	getSysType() {
		return this.httpService.httpPost(`${config.SysCodeUrl}/GetSysType`, {});
	}
	/** 取得可編輯的系統代碼類型 */
	getEditSysType() {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/GetEditableSysType`,
			{},
		);
	}

	/** 新增代碼 */
	createSysCode(dataSrc) {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/CreateSysCode`,
			dataSrc,
		);
	}

	/** 編輯、作廢代碼 */
	editSysCode(dataSrc) {
		return this.httpService.httpPost(
			`${config.SysCodeUrl}/EditSysCode`,
			dataSrc,
		);
	}
}
