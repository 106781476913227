import {
	JsonApiService,
	UserService,
	MessageService,
	LayoutService,
} from '@app/core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Action } from '@ngrx/store';
import * as actions from './user.actions';
import { User, ResponseObj } from '@app/core/models';
import {
	map,
	catchError,
	tap,
	exhaustMap,
} from 'rxjs/internal/operators';
import { of } from 'rxjs/observable/of';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';

@Injectable()
export class UserEffects {
	constructor(
		private action$: Actions,
		private router: Router,
		private userService: UserService,
		private jsonApiService: JsonApiService,
		private messageService: MessageService,
		private layoutService: LayoutService
	) {}

	message: string;

	login$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(actions.UserActionTypes.Login),
			exhaustMap((action: actions.LoginAction) => {
				// return this.jsonApiService.fetch("/user/login-info.json").pipe(
				return this.userService.userLogin(action.payload).pipe(
					map((res: ResponseObj) => {
						this.layoutService.setPageLoading(false);
						if (res.code == '000') {
							return new actions.LoginSuccessAction(res.body);
						} else if (res.code == '117') {
							this.message = '驗證碼不符，請確認驗證碼';
							return new actions.LoginFailAction(res.body);
						} else if (res.code == '119') {
							this.message = '帳號或密碼錯誤，請確認帳號密碼';
							return new actions.LoginFailAction(res.body);
						}
					}),
					catchError(err => of(new actions.LoginFailAction(err))),
				);
			}),
		),
	);

	loginSuccess$: Observable<Action> = createEffect(
		() =>
			this.action$.pipe(
				ofType(actions.UserActionTypes.LoginSuccess),
				tap((user: actions.LoginSuccessAction) => {
					this.messageService.add({
						severity: 'success',
						summary: '登入成功',
						detail: '歡迎使用社交工程分析平台',
						life: 3000,
					});
					setTimeout(() => {
						this.router.navigateByUrl('/Bulletin');
					}, 1000);
				}),
			),
		{ dispatch: false },
	);

	loginFail$: Observable<Action> = createEffect(
		() =>
			this.action$.pipe(
				ofType(actions.UserActionTypes.LoginFail),
				tap(user => {
					console.log('message:', this.message);
					this.messageService.add({
						severity: 'error',
						summary: '登入失敗',
						detail: this.message,
						life: 3000,
					});
				}),
			),
		{ dispatch: false },
	);

	logout$: Observable<Action> = createEffect(() =>
		this.action$.pipe(
			ofType(actions.UserActionTypes.Logout),
			exhaustMap((action: actions.LogoutAction) => {
				// switchMap((action: actions.LoginAction) => {
				return this.jsonApiService.fetch('/user/login-info.json').pipe(
					// return this.userService.userLogout(action.payload).pipe(
					map((res: ResponseObj) => {
						if (res.code === '000') {
							return new actions.LogoutSuccessAction();
						} else {
							return new actions.LogoutFailAction(res.body[0]);
						}
					}),
					catchError(err => of(new actions.LogoutFailAction(err))),
				);
			}),
		),
	);

	// @Effect()
	// logout$: Observable<Action> = this.action$.pipe(
	//   ofType(actions.UserActionTypes.Logout),
	//   exhaustMap((action: actions.LogoutAction) => {
	//     // switchMap((action: actions.LoginAction) => {
	//     return this.userService.userLogout(action.payload).pipe(
	//       map((res) => new actions.LogoutSuccessAction()),
	//       catchError((err) => of(new actions.LogoutFailAction(err)))
	//       // map((res) => {
	//       //     if(res.Status == "Success") {
	//       //         return (new actions.LogoutSuccessAction());
	//       //     } else {
	//       //         // console.log(res);
	//       //         // return (new actions.LogoutFailAction());
	//       //     }
	//       //     // console.log(res);
	//       //     // localStorage.removeItem('currentUser');
	//       // }),
	//       // catchError((err) => of(new actions.LoginFailAction(err)))
	//     );
	//   })
	// );

	logoutSuccess$: Observable<Action> = createEffect(
		() =>
			this.action$.pipe(
				ofType(actions.UserActionTypes.LogoutSuccess),
				tap((user: actions.LogoutSuccessAction) => {
					this.messageService.add({
						severity: 'success',
						summary: '已登出',
						detail: '請重新登入',
						life: 3000,
					});
					this.router.navigateByUrl('/Web/Login');
				}),
			),
		{ dispatch: false },
	);

	logoutFail$: Observable<Action> = createEffect(
		() =>
			this.action$.pipe(
				ofType(actions.UserActionTypes.LogoutFail),
				tap((action: actions.LogoutFailAction) => {
					this.messageService.add({
						severity: 'error',
						summary: '登入逾時',
						detail: '請重新登入',
						life: 3000,
					});
					this.router.navigateByUrl('/Web/Login');
				}),
			),
		{ dispatch: false },
	);

	// @Effect()
	// updateUser$: Observable<Action> = this.action$.pipe(
	//   ofType(actions.UserActionTypes.UpdateUser),
	//   // map((action: actions.LoginAction) => action.payload),
	//   exhaustMap((action: actions.LoginAction) => {
	//     // switchMap((action: actions.LoginAction) => {
	//     return this.userService.userLogin(action.payload).pipe(
	//       map((res: User) => {
	//         if (res.ID) {
	//           // console.log(res.UserId)
	//           return new actions.UpdateUserSuccessAction(res);
	//         } else {
	//           return new actions.UpdateUserFailAction();
	//         }
	//       }),
	//       catchError((err) => of(new actions.UpdateUserFailAction()))
	//     );
	//   })
	// );

	// @Effect()
	// singleSignOn$: Observable<Action> = this.action$.pipe(
	//   ofType(actions.UserActionTypes.SingleSignOn),
	//   exhaustMap((action: actions.SingleSignOnAction) => {
	//     return this.userService.singleSignOn(action.payload).pipe(
	//       map((res: ResponseObj) => {
	//         console.log(res);
	//         if (res.isSuccess) {
	//           // console.log('前往:', res.body.Url )
	//           // window.location.href = String(res.body.Url);
	//           return new actions.SingleSignOnSuccessAction(res.body);
	//         } else {
	//           return new actions.SingleSignOnFailAction();
	//         }
	//       }),
	//       catchError((err) => of(new actions.SingleSignOnFailAction()))
	//     );
	//   })
	// );

	// @Effect({ dispatch: false })
	// singleSignOnSuccess$: Observable<Action> = this.action$.pipe(
	//   ofType(actions.UserActionTypes.SingleSignOnSuccess),
	//   tap((user: actions.SingleSignOnSuccessAction) => {
	//     console.log(user);
	//     this.notificationService.smallBox({
	//       title: '單登成功',
	//       content: "<em class='fa fa-clock-o'></em><em>進入監控中心個人儀錶板</em>",
	//       color: 'var(--color-success)',
	//       iconSmall: 'fa fa-thumbs-up bounce animated',
	//       timeout: 2000,
	//     });
	//     this.router.navigateByUrl('/App/AppLaunch');
	//   })
	// );

	// // for ap
	// @Effect({ dispatch: false })
	// singleSignOnFail$: Observable<Action> = this.action$.pipe(
	//   ofType(actions.UserActionTypes.SingleSignOnFail),
	//   tap((user: actions.SingleSignOnFailAction) => {
	//     this.notificationService.smallBox({
	//       title: '單登失敗',
	//       content: "<em class='fa fa-clock-o'></em> <em>將於兩秒後重整頁面</em>",
	//       color: 'var(--color-danger)',
	//       iconSmall: 'fa fa-warning bounce animated',
	//       timeout: 2000,
	//     });
	//     this.router.navigateByUrl('/Web/Login');
	//     setTimeout(() => {
	//       window.location.reload();
	//     }, 2000);
	//   })
	// );

	// for sso
	// @Effect({ dispatch: false })
	// singleSignOnFail$: Observable<Action> = this.action$.pipe(
	//     ofType(actions.UserActionTypes.SingleSignOnFail),
	//     tap((user: actions.SingleSignOnFailAction) => {
	//         console.log(user)
	//         this.router.navigateByUrl('/Miscellaneous/ErrorPage');
	//     })
	// );
}
