import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { CalendarModule } from 'primeng/calendar';
import { NgIf } from '@angular/common';

@Component({
    selector: 'sa-basic-datepicker',
    templateUrl: './basic-datepicker.component.html',
    styleUrls: ['./basic-datepicker.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, CalendarModule]
})
export class BasicDatepickerComponent {
  @Input() ctrl: string;
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() must = false;
  @Input() invalidStyle: string;
  /** 時間限制 */
  @Input() minDate: Date;
  @Input() maxDate: Date;
  /** 控制使用者是否可選取時間 */
  @Input() disabled = false;
  /** 時間 */
  @Input() showTime = false;

  tw: any;

  constructor() {
    this.tw = {
		firstDayOfWeek: 0,
		dayNames: ['日', 'ㄧ', '二', '三', '四', '五', '六'],
		dayNamesShort: ['日', 'ㄧ', '二', '三', '四', '五', '六'],
		dayNamesMin: ['日', 'ㄧ', '二', '三', '四', '五', '六'],
		monthNames: [
			'一月',
			'二月',
			'三月',
			'四月',
			'五月',
			'六月',
			'七月',
			'八月',
			'九月',
			'十月',
			'十一月',
			'十二月',
		],
		monthNamesShort: [
			'一月',
			'二月',
			'三月',
			'四月',
			'五月',
			'六月',
			'七月',
			'八月',
			'九月',
			'十月',
			'十一月',
			'十二月',
		],
		today: '當日',
		clear: '清除',
		dateFormat: 'mm-dd-yy',
		weekHeader: 'Wk',
	};
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.minDate &&
      changes.minDate.currentValue
    ) {
      const minDate = moment(changes.minDate.currentValue).toString();
      this.minDate = new Date(minDate);
      console.log('new Date(Date.parse(minDate)):', new Date(Date.parse(minDate)))
      this.minDate.setMonth(new Date(Date.parse(minDate)).getMonth());
      
      this.minDate.setFullYear(new Date(Date.parse(minDate)).getFullYear());
      console.log('this.minDate:', this.minDate)
      // console.log('minDate:', minDate)
    }

  }

  /**
*  依據p-calendar改變時，回傳字串類型的日期格式
* @param event 數值
* @param formGroup 即將改變的formGroup
* @param formControlName 即將改變的formControlName
*/
  onSelectMethodForCalendarFormat(event, formGroup: FormGroup, formControlName) {
    const d = new Date(Date.parse(event));
    const patchValue: object = {};
    patchValue[formControlName] = moment(d).format('YYYY-MM-DD HH:mm:ss');
    formGroup.patchValue(patchValue)
  }


}

