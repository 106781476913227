import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor() { }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const header = Object.keys(json[0]); // columns name

        let wscols = [];
        header.forEach((el) => wscols.push({ wch: el.length + 15 }));

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        worksheet['!cols'] = wscols;
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    public exportAsExcelFileWithoutTime(json: any[], excelFileName: string): void {
        const header = Object.keys(json[0]); // columns name

        let wscols = [];
        header.forEach((el) => wscols.push({ wch: el.length + 15 }));

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        worksheet['!cols'] = wscols;
        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        this.saveAsExcelFileWithoutTime(excelBuffer, excelFileName);
    }

    private saveAsExcelFileWithoutTime(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            fileName + EXCEL_EXTENSION
        );
    }
}
