<div class="project-context hidden-xs dropdown" dropdown>
	<span class="label">{{ 'Projects' }}:</span>
	<span class="project-selector dropdown-toggle" dropdownToggle>
		{{ 'Recent projects' }}
		<em ng-if="projects.length" class="fa fa-angle-down"></em>
	</span>

	<ul *dropdownMenu class="dropdown-menu">
		<li *ngFor="let project of projects">
			<a href="{{ project.href }}">{{ project.title }}</a>
		</li>
		<li class="divider"></li>
		<li>
			<a (click)="clearProjects()">
				<em class="fa fa-power-off"></em>
				Clear
			</a>
		</li>
	</ul>
</div>
