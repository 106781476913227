import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'moment',
	standalone: true,
})
export class MomentPipe implements PipeTransform {
	transform(value: any, format?: any): any {
		return moment(value).format(format);
	}
}
