import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@app/core/models';
import { Store, select } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import {
	Router,
	Event,
	NavigationEnd,
	RouterLinkActive,
	RouterLink,
} from '@angular/router';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { SmartMenuDirective } from './smart-menu.directive';
import { MinifyMenuComponent } from './minify-menu.component';

@Component({
	selector: 'sa-navigation',
	templateUrl: './navigation.component.html',
	standalone: true,
	imports: [
		MinifyMenuComponent,
		SmartMenuDirective,
		NgFor,
		RouterLinkActive,
		RouterLink,
		NgIf,
		NgClass,
	],
})
export class NavigationComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;

	icons = {
		Home: 'fa-solid fa-house', // 首頁
		Dashboard: 'fa-solid fa-columns', // 儀表板
		Bulletin: 'fa-solid fa-bullhorn', // 公告
		Template: 'fa-solid fa-file-pen', // 範本
		Case: 'fa-solid fa-briefcase', // 演練案件
		Train: 'fa-solid fa-envelope-open-text', // 教育訓練
		Analysis: 'fa-solid fa-circle-nodes', // 關聯分析
		File: 'fa-solid fa-folder-open', // 檔案
		StatisticalReport: 'fa-solid fa-chart-column', // 統計報表
		System: 'fa-solid fa-gear', // 系統管理
	};

	list: unknown = [
		// {
		// 	// Name: '系統首頁',
		// 	Name: '儀表板',
		// 	Id: 'Dashboard',
		// 	Selected: true,
		// 	Children: [],
		// },
		{
			Name: '佈告欄',
			Id: 'Bulletin',
			Selected: true,
			Children: [],
		},
		{
			Name: '範本管理',
			Id: 'Template',
			Selected: true,
			Children: [
				{
					Name: '新增範本',
					Id: 'TemplateCreate',
					Selected: true,
					Children: [],
				},
				{
					Name: '範本列表',
					Id: 'TemplateManage',
					Selected: true,
					Children: [],
				},
			],
		},
		{
			Name: '演練案件管理',
			Id: 'Case',
			Selected: true,
			Children: [
				{
					Name: '新增演練',
					Id: 'CaseCreate',
					Selected: true,
					Children: [],
				},
				{
					Name: '演練進度',
					Id: 'CaseManage',
					Selected: true,
					Children: [],
				},
			],
		},
		{
			Name: '教育訓練發布',
			Id: 'Train',
			Selected: true,
			Children: [
				{
					Name: '新增發布',
					Id: 'TrainCreate',
					Selected: true,
					Children: [],
				},
				{
					Name: '範本管理',
					Id: 'TrainManage',
					Selected: true,
					Children: [],
				},
				{
					Name: '發布列表',
					Id: 'TrainList',
					Selected: true,
					Children: [],
				},
			],
		},
		{
			Name: '統計報表',
			Id: 'StatisticalReport',
			Selected: true,
			Children: [
				{
					Name: '行為統計表',
					Id: 'BehaviorReport',
					Selected: true,
					Children: [],
				},
				{
					Name: '範本類型統計表',
					Id: 'CaseTypeReport',
					Selected: true,
					Children: [],
				},
				{
					Name: '單位部室統計表',
					Id: 'DeptReport',
					Selected: true,
					Children: [],
				},
			],
		},
		{
			Name: '系統管理',
			Id: 'System',
			Selected: true,
			Children: [
				{
					Name: '系統公告設定',
					Id: 'BulletinSetting',
					Selected: true,
					Children: [],
				},
				{
					Name: '稽核紀錄管理',
					Id: 'UserLogRecord',
					Selected: true,
					Children: [],
				},
				{
					Name: '帳號權限管理',
					Id: 'AuthorityManagement',
					Selected: true,
					Children: [],
				},
				{
					Name: '問題回報管理',
					Id: 'ReportManagement',
					Selected: true,
					Children: [],
				},
				{
					Name: '個人帳號管理',
					Id: 'UserManagement',
					Selected: true,
					Children: [],
				},
			],
		},
	];
	navList = [];

	currentURL;

	// 頁籤顯示
	constructor(
		private store: Store<fromUser.UserState>,
		public router: Router,
	) {
		this.currentUser$ = this.store.select(fromUser.getCurrentUser);
		this.currentUser$.subscribe(res => {
			if (res === null) return;
			const _permissionTree = JSON.parse(
				JSON.stringify(res.PermissionTree)
			);
			// const _permissionTree = JSON.parse(JSON.stringify(this.list));
			// 深拷貝
			this.InitNav(_permissionTree);
		});
	}

	ngOnInit() {
		// this.currentUser = await this.currentUser$.take(1).toPromise<User>();

		// same URL trigger reload
		this.currentURL = this.router.url;

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (this.currentURL === event.url) {
					window.location.reload();
				}
				this.currentURL = event.url;
			}
		});
	}

	InitNav(list) {
		console.log('list:', list);
		const sort = obj => {
			const newChild = obj.Children.filter(r => {
				// 判斷第三層
				if (r.Children?.length) {
					return r;
				} else {
					return r.Selected;
				}
			});

			return { ...obj, Children: newChild };
		};
		const buildData = arr => {
			return arr.flatMap(r => {
				return {
					path: r.Id,
					name: r.Name,
					id: r.Id,
					icon: this.icons[r.Id],
					subList: r.Children?.flatMap(i => {
						return {
							path: `/${r.Id}/${i.Id}`,
							name: `${i.Name}`,
							subList: i.Children?.flatMap(a => {
								if (a) {
									return {
										path: `/${r.Id}/${i.Id}/${a.Id}`,
										name: `${a.Name}`,
										subList: a.Children?.flatMap(t => {
											if (t) {
												return {
													path: `/${r.Id}/${i.Id}/${a.Id}/${t.Id}`,
													name: `${t.Name}`,
												};
											}
										}),
									};
								}
							}),
						};
					}),
				};
			});
		};

		const result = list
			.map((item: any) => {
				console.log('item:', item);
				if (item.Children.length > 0 ) {
					item.Children.map((x: any) => {
						// console.log('第二層 x.Children:', x.Children);
						if (x.Children && x.Children.length > 0) {
							const newChild = x.Children.filter(m => m.Selected);
							// console.log('第三層 newChild:', newChild);
							x.Children = JSON.parse(JSON.stringify(newChild));
						}
					});
				}
				return sort(item);
			})
			.filter(r => r.Selected);

		this.navList = buildData(result);
		console.log('this.navList:', this.navList);
	}
}
