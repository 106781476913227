import { version } from './version';

export const environment = {
	// 關閉顯示 console.log
	closeConsole: false,

	/** INFO:坤侑測試機 */
	baseUrl: 'SEPWebApi/',
	downloadUrl: document.getElementsByTagName('base')[0].href,

	// 控制網址 #, 單登要 false
	urlUseHash: { useHash: true },
	// INFO:開啟單登
	// 測試單登網誌 => /Web/Login?id=Administrator&unitid=27
	// app-routing.module 萬用路由導頁
	// user.service.ts 單登 API singleSignIn(dataSrc: unknown)
	// user.effects.ts 控制登出導頁 logoutSuccess$()
	// login.html 控制登入call單登API, store.dispatch(new fromUser.SingleSignOnAction(cmd))
	singleSignIn: false,

	// footer 版號
	version: version,
	// 專案名稱:中文
	projectNameCH: '社交工程分析平台',
	// 專案名稱:英文
	projectNameEN: 'Social Engineering Analysis System',

	firebase: {},

	debug: false,
	log: {
		auth: false,
		store: false,
	},

	smartadmin: {
		api: null,
		db: 'smartadmin-angular',
	},
};
