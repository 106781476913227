import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import { Store } from "@ngrx/store";
// import * as fromUser from '@app/core/store/user';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector // private store: Store<fromUser.UserState>,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let userInfo: any = localStorage.getItem('user');
    let userId;

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.isLogin) {
        // console.log('userInfo.isLogin', userInfo.isLogin);
        userId = userInfo.currentUser.UserId;
      }
    }

    const customRequest = userId
      ? request.clone({
          headers: request.headers
            .set('ClientId', userId)
            .set('ApiKey', '5a0e837088b649648b14c8396274348c'),
        })
      : request;

    return next.handle(customRequest);
    // .do((ev: HttpEvent<any>) => {
    //   if (ev instanceof HttpResponse) {
    //     // console.log('processing response', ev);
    //   }
    // })
    // .catch(response => {
    //   if (response instanceof HttpErrorResponse) {
    //     // console.log('processing http error', response);
    //   }

    // throw已廢棄
    //   return Observable.throw(response);
    // });

    // return this.store.select(fromUser.getCurrentUser).take(1).flatMap((modifiedReq) => {
    //   // console.log(modifiedReq);
    //   const customRequest = modifiedReq ? request.clone({
    //     headers: request.headers.set("ClientId", modifiedReq.UserId).set("ApiKey", "5a0e837088b649648b14c8396274348c")
    //   }) : request;

    //   return next.handle(customRequest)
    //     .do((ev: HttpEvent<any>) => {
    //       if (ev instanceof HttpResponse) {
    //         // console.log('processing response', ev);
    //       }
    //     })
    //     .catch(response => {
    //       if (response instanceof HttpErrorResponse) {
    //         // console.log('processing http error', response);
    //       }

    //       return Observable.throw(response);
    //     });
    // });
  }
}
