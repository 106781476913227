import { Component, OnInit } from '@angular/core';
import { RecentProjectsService } from './recent-projects.service';
// import { I18nPipe } from '../../../i18n/i18n.pipe';
import { NgFor } from '@angular/common';

@Component({
	selector: 'sa-recent-projects',
	templateUrl: './recent-projects.component.html',
	providers: [RecentProjectsService],
	standalone: true,
	imports: [NgFor],
})
export class RecentProjectsComponent implements OnInit {
	projects: Array<any>;

	constructor(private projectsService: RecentProjectsService) {}

	ngOnInit() {
		this.projects = this.projectsService.getProjects();
	}

	clearProjects() {
		this.projectsService.clearProjects();
		this.projects = this.projectsService.getProjects();
	}
}
