<div class="page-footer">
	<div class="row no-gutters">
		<div class="col-sm-12 col-sm-6">
			<span>
				{{ environment.projectNameCH }} {{ environment.projectNameEN }}
				©
				{{ environment.version }}
			</span>
		</div>
	</div>
</div>
