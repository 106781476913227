import { Component, OnInit, OnDestroy } from '@angular/core';
import { config } from '@app/core/smartadmin.config';
import { LayoutService } from '@app/core/services/layout.service';
import { Subscription } from 'rxjs';
import { NgFor, NgStyle, NgIf } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';

declare var $: any;

@Component({
    selector: 'sa-layout-switcher',
    templateUrl: './layout-switcher.component.html',
    standalone: true,
    imports: [FormsModule, ButtonModule, NgFor, NgStyle, NgIf]
})
export class LayoutSwitcherComponent implements OnInit, OnDestroy {
	isActivated: boolean;
	smartSkin: string;
	store: any;
	private sub: Subscription;

	scale: number = 14; // font 大小調整

	constructor(public layoutService: LayoutService) {}

	ngOnInit() {
		this.sub = this.layoutService.subscribe((store) => {
			this.store = store;
		});
		this.store = this.layoutService.store;
		// console.log('齒輪 this.store:', this.store);
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	onToggle() {
		this.isActivated = !this.isActivated;
	}

	onSmartSkin(skin) {
		this.layoutService.onSmartSkin(skin);
	}

	onFixedHeader() {
		this.layoutService.onFixedHeader();
	}

	onFixedNavigation() {
		this.layoutService.onFixedNavigation();
	}

	onFixedRibbon() {
		this.layoutService.onFixedRibbon();
	}

	onFixedPageFooter() {
		this.layoutService.onFixedPageFooter();
	}

	onInsideContainer() {
		this.layoutService.onInsideContainer();
	}

	onRtl() {
		this.layoutService.onRtl();
	}

	onMenuOnTop() {
		this.layoutService.onMenuOnTop();
	}

	onColorblindFriendly() {
		this.layoutService.onColorblindFriendly();
	}

	factoryReset() {
		this.layoutService.factoryReset();
	}

	// INFO:全部畫面 fontSize 調整
	incrementScale() {
		this.scale++;
		this.applyScale();
	}

	decrementScale() {
		this.scale--;
		this.applyScale();
	}

	applyScale() {
		document.documentElement.style.fontSize = this.scale + 'px';
		console.log('字型大小調整 this.scale:', this.scale);
		console.log(
			'字型大小調整 document.documentElement.style.fontSize:',
			document.documentElement.style
		);
	}
}
