import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { RecentProjectsComponent } from './recent-projects/recent-projects.component';
// import { FullScreenComponent } from './full-screen/full-screen.component';

import { ActivitiesComponent } from './activities/activities.component';
import { ActivitiesMessageComponent } from './activities/activities-message/activities-message.component';
import { ActivitiesNotificationComponent } from './activities/activities-notification/activities-notification.component';
import { ActivitiesTaskComponent } from './activities/activities-task/activities-task.component';
import { HeaderComponent } from './header.component';
// import { SharedModule } from '@app/shared/shared.module';

import { I18nModule } from '@app/shared/i18n/i18n.module';

// import { VoiceControlModule } from '@app/shared/voice-control/voice-control.module';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { PopoverModule } from 'ngx-bootstrap/popover';
// import { AccordionModule } from 'ngx-bootstrap/accordion';
// import { CarouselModule } from 'ngx-bootstrap/carousel';

/** ngx倒數 */
import { CountdownModule } from 'ngx-countdown';

// PrimeNg
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
@NgModule({
	imports: [
		CommonModule,
		// SharedModule,
		FormsModule,
		I18nModule,
		// AccordionModule.forRoot(),
		// PopoverModule.forRoot(),
		// CarouselModule.forRoot(),
		CountdownModule,
		// PrimeNg
		ConfirmDialogModule,
		ConfirmPopupModule,
		ActivitiesMessageComponent,
		ActivitiesNotificationComponent,
		ActivitiesTaskComponent,
		RecentProjectsComponent,
		// FullScreenComponent,
		// CollapseMenuComponent,
		ActivitiesComponent,
		HeaderComponent,
	],
	exports: [HeaderComponent],
})
export class HeaderModule {}
