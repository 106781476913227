import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, tap } from "rxjs/operators";
import { LayoutService } from "@app/core/services/layout.service";
import { LayoutActionTypes } from "./layout.actions";

@Injectable()
export class LayoutEffects {
  
  onSmartSkin$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.SmartSkin),
    tap((action: any) => this.layoutService.onSmartSkin(action.payload))
  ), { dispatch: false });

  
  onFixedHeader$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.FixedHeader),
    tap(action => this.layoutService.onFixedHeader)
  ), { dispatch: false });
  
  onFixedNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.FixedNavigation),
    tap(action => this.layoutService.onFixedNavigation)
  ), { dispatch: false });
  
  onFixedRibbon$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.FixedRibbon),
    tap(action => this.layoutService.onFixedRibbon)
  ), { dispatch: false });
  
  onFixedPageFooter$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.FixedPageFooter),
    tap(action => this.layoutService.onFixedPageFooter)
  ), { dispatch: false });
  
  onInsideContainer$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.InsideContainer),
    tap(action => this.layoutService.onInsideContainer)
  ), { dispatch: false });
  
  onRtl$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.Rtl),
    tap(action => this.layoutService.onRtl)
  ), { dispatch: false });
  
  onMenuOnTop$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.MenuOnTop),
    tap(action => this.layoutService.onMenuOnTop)
  ), { dispatch: false });
  
  onColorblindFriendly$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.ColorblindFriendly),
    tap(action => this.layoutService.onColorblindFriendly)
  ), { dispatch: false });
  
  onCollapseMenu$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.CollapseMenu),
    tap(action => this.layoutService.onCollapseMenu)
  ), { dispatch: false });
  
  onMinifyMenu$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.MinifyMenu),
    tap(action => this.layoutService.onMinifyMenu)
  ), { dispatch: false });
  
  onShortcutToggle$ = createEffect(() => this.actions$.pipe(
    ofType(LayoutActionTypes.ShortcutToggle),
    tap(action => this.layoutService.onShortcutToggle)
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private layoutService: LayoutService
  ) {}
}
