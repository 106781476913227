import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { config } from '@app/core/app.config';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserService {
	constructor(
		private http: HttpClient,
		private httpService: HttpClientService
	) { }

	/** 使用者-使用者登入 */
	userLogin(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UserLogin`,
			dataSrc
		);
	}

	/** 使用者-使用者登出 */
	userLogout(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UserLogout`,
			dataSrc
		);
	}

	/** 帳號權限管理-修改密碼 */
	changePassword(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/ChangePassword`,
			dataSrc
		);
	}

	// /** 單一登入 */
	// singleSignOn(dataSrc: any): Observable<any> {
	//   return this.httpService.httpPost(
	//     `${config.SingleUrl}/SingleSignOn`,
	//     dataSrc
	//   );
	// }

	/** 取得使用者資訊 */
	public getUserInfoList(): Observable<any> {
		return this.httpService.httpGet(
			`${config.UserInfoUrl}/GetUserInfoList`
		);
	}

	/** 帳號權限管理-帳號新增 */
	public createUserInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/CreateUserInfo`,
			dataSrc
		);
	}

	/** 更新使用者 */
	public updateUserInfo(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UpdateUserInfo`,
			dataSrc
		);
	}

	/** 更新單位 */
	public updateUserDept(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UpdateUserDept`,
			dataSrc
		);
	}

	/** 取得單位列表 */
	public getUserDept(): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/GetUserDept`,
			''
		);
	}

	/** 新增職稱 */
	public createUserGrade(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/CreateUserGrade`,
			dataSrc
		);
	}

	/** 更新職稱 */
	public updateUserGrade(dataSrc: any): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/UpdateUserGrade`,
			dataSrc
		);
	}

	/** 取得職稱列表 */
	public getUserGrade(): Observable<any> {
		return this.httpService.httpPost(
			`${config.UserInfoUrl}/GetUserGrade`,
			''
		);
	}

	/** 圖形驗證 */
	public getCaptcha(): string {
		return `${config.AuthorizationUrl}` + '?t=' + new Date().getTime();
	}

}
