<p-toast key="root" [baseZIndex]="999999"></p-toast>

<div class="main__layout">
  <sa-header></sa-header>

  <sa-navigation *ngIf="currentUser"></sa-navigation>

  <div id="main" class="" role="main">
    <router-outlet #o="outlet"></router-outlet>
    <!-- 增加 toast 讓子層也可以控制 -->
    <p-toast></p-toast>
    <!-- <p-toast key="logOut" position="top-center"></p-toast> -->
  </div>

  <sa-layout-switcher class="d-none demo_custom"></sa-layout-switcher>

  <sa-footer class="footer__style"></sa-footer>

  <p-blockUI
    [baseZIndex]="999999"
    [blocked]="this.layoutService.pageLoading$ | async"
  >
    <p-progressSpinner></p-progressSpinner>
  </p-blockUI>
</div>
