<form [formGroup]="form">
	<div class="d-flex col-sm-12 col-md-6">
		<label>
			<code *ngIf="must">*</code>
			{{ label ? label : '時間' }}
		</label>
		<div class="d-flex time-picker-minmax-wrap">
			<!-- 開始時間 -->
			<div class="time-picker">
				<p-calendar
					placeholder="請選取開始月份"
					view="month"
					dateFormat="yy-mm"
					yearRange="1911:2025"
					[locale]="tw"
					[(ngModel)]="initStartDate"
					[ngModelOptions]="{ standalone: true }"
					(onSelect)="onSelectStart($event, form, ctrlStart)"
					[dataType]="'string'"
					[showIcon]="true"
					[maxDate]="maxDate"
					[showButtonBar]="true"
					[readonlyInput]="true"
					[monthNavigator]="false"
					[yearNavigator]="true"
				></p-calendar>
			</div>

			&nbsp;&nbsp;至&nbsp;&nbsp;

			<!-- 結束時間 -->
			<div class="time-picker">
				<p-calendar
					placeholder="請選取結束月份"
					view="month"
					dateFormat="yy-mm"
					yearRange="1911:2025"
					[locale]="tw"
					[(ngModel)]="initEndDate"
					[ngModelOptions]="{ standalone: true }"
					(onSelect)="onSelectEnd($event, form, ctrlEnd)"
					[dataType]="'string'"
					[showIcon]="true"
					[minDate]="minDate"
					[maxDate]="maxDate"
					[showButtonBar]="true"
					[readonlyInput]="true"
					[monthNavigator]="false"
					[yearNavigator]="true"
				></p-calendar>
			</div>
		</div>
	</div>
</form>
