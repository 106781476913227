import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { config } from '@app/core/app.config';

@Injectable({
	providedIn: 'root'
})
export class TrainingService {

	constructor(
		private httpService: HttpClientService
	) { }

	/** 教育訓練範本下拉選單(不包含手動設定) */
	public getTrainingDropdownList(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetTrainingDropdownList`, param);
	}

	/** 新增發布範本 */
	public createTrainingForm(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/CreateTrainingForm`, param);
	}

	/** 編輯發布範本 */
	public updateTrainingFormList(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/UpdateTrainingFormList`, param);
	}

	/** 刪除發布範本 */
	public deleteTrainingTask(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/DeleteTrainingTask`, param);
	}

	/** 刪除訓練附件檔案 */
	public deleteFile(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/DeleteFile`, param);
	}

	/** 取得範本管理清單 */
	public getTrainingFormList(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetTrainingFormList`, param);
	}

	/** 取得訓練範本明細 */
	public getTrainingFormDetail(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetTrainingFormDetail`, param);
	}

	/** 取得訓練通知清單 */
	public getTrainingTaskList1(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetTrainingTaskList1`, param);
	}

	/** 取得訓練通知明細 */
	public GetTrainingTaskDetailList(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetTrainingTaskDetailList`, param);
	}

	/** 選擇演練下拉選單 */
	public getMailTaskList(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetMailTaskList`, param);
	}

	/** 選擇演練下拉選單 */
	public getTrainingList(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetTrainingList`, param);
	}

	/** 教育訓練範本下拉選單(所有範本) */
	public getTrainingAllDropdownList(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/GetTrainingAllDropdownList`, param);
	}

	/** 取消發布 */
	public cancelTrainingTask(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/CancelTrainingTask`, param);
	}

	/** 新增發布範本:範本列表*/
	public createTrainingFormTemplate(param): Observable<any> {
		return this.httpService.httpPost(`${config.TrainingCaseUrl}/CreateTrainingFormTemplate`, param);
	}

}
