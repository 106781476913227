import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderModule } from './header/header.module';
import { FooterComponent } from './footer/footer.component';
import { NavigationModule } from './navigation/navigation.module';
import { RibbonComponent } from './ribbon/ribbon.component';
import { ShortcutComponent } from './shortcut/shortcut.component';
import { ToggleActiveDirective } from '../utils/toggle-active.directive';
import { LayoutSwitcherComponent } from './layout-switcher.component';
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { RouterModule } from '@angular/router';
import { LoginLayoutComponent } from '@app/shared/layout/app-layouts/login-layout.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DashboardLayoutComponent } from './app-layouts/dashboard-layout.component';

@NgModule({
    imports: [
    CommonModule,
    HeaderModule,
    NavigationModule,
    FormsModule,
    RouterModule,
    // TooltipModule,
    // BsDropdownModule,
    ConfirmDialogModule,
    // PrimeNg
    ToastModule,
    ProgressSpinnerModule,
    BlockUIModule,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    MainLayoutComponent,
    LoginLayoutComponent,
    DashboardLayoutComponent,
    RouteBreadcrumbsComponent
],
    exports: [
        HeaderModule,
        NavigationModule,
        FooterComponent,
        RibbonComponent,
        ShortcutComponent,
        LayoutSwitcherComponent,
    ]
})
export class SmartadminLayoutModule { }
