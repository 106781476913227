import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgIf, AsyncPipe } from '@angular/common';
import * as _ from 'lodash';
/*-- service & model --*/
import {
	SyscodeService,
	LayoutService,
	MessageService,
	// I18nService,
	ProblemReportService,
} from '@app/core/services';
import { User } from '@app/core/models';
/*-- store & Rxjs --*/
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import {  Observable, of } from 'rxjs';
/*-- component & module --*/
import { ConfirmationService, SharedModule } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CountdownComponent, CountdownModule } from 'ngx-countdown';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ActivitiesComponent } from './activities/activities.component';
import { CollapseMenuComponent } from './collapse-menu/collapse-menu.component';
import { DialogModule } from 'primeng/dialog';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from '@env/environment';
import { finalize, switchMap, tap } from 'rxjs/operators';

declare var $: any;

@Component({
	selector: 'sa-header',
	templateUrl: './header.component.html',
	standalone: true,
	providers: [DialogService],
	imports: [
		ActivitiesComponent,
		CollapseMenuComponent,
		NgIf,
		CountdownModule,
		ConfirmDialogModule,
		SharedModule,
		ButtonModule,
		ConfirmPopupModule,
		DropdownModule,
		AsyncPipe,
		I18nModule,
		OverlayPanelModule,
		DialogModule,
		InputTextModule,
		InputTextareaModule,
		ReactiveFormsModule,
	],
})
export class HeaderComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;
	environment = environment;

	@ViewChild('countdown', { static: true }) countdown: CountdownComponent;

	// INFO:正式用
	leftTime: number = 1200; // 倒數時間(秒) 20分鐘
	notify: number = 300; // 警示秒數:reLogin() 5分鐘
	checkPositionTime: number = 0.01; // 每1秒 檢測鼠標歷史位置 與 現位置 差異

	// INFO:測式用
	// leftTime: number = 60; // 倒數時間(秒) 1分鐘
	// notify: number = 30; // 警示秒數:reLogin() 秒
	// checkPositionTime: number = 0.01; // 每1秒 檢測鼠標歷史位置 與 現位置 差異

	countdownConfig = {
		leftTime: this.leftTime,
		notify: [this.notify],
		format: '登出倒數：m分s秒',
		demand: false,
	};

	// 滑鼠座標
	mXCurrent: number = 0;
	mYCurrent: number = 0;
	mXBefore: number = 0;
	mYBefore: number = 0;

	// 全螢幕符號
	fullScreenSign: string = 'fa-solid fa-expand fa-lg';

	intervalId: any;

	langOption: { value: string; label: string }[] = [];

	reportFormModal = false;
	reportForm = this.formBuilder.group({
		Menu: [{ value: '', disabled: true }, Validators.required],
		Subject: ['', Validators.required],
		ProblemType: ['', Validators.required],
		ReportContent: ['', Validators.required],
	});

	typeItems = [
		{ label: '問題', value: '1' },
		{ label: '需求', value: '2' },
	];

	constructor(
		private router: Router,
		private store: Store<fromUser.UserState>,
		private formBuilder: UntypedFormBuilder,
		private layoutService: LayoutService,
		public syscodeService: SyscodeService,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		public dialogService: DialogService,
		public problemReportService: ProblemReportService,
		// public i18nService: I18nService,
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
		this.currentUser$.subscribe(v => {
			this.currentUser = v;
		});
	}

	ngOnInit(): void {
		this.leftTime = localStorage.getItem('AutuLogOutMinute')
			? Number(localStorage.getItem('AutuLogOutMinute')) * 60
			: this.leftTime;
		this.countdownConfig.leftTime = this.leftTime;

		this.syscodeService.initSyscode();
		
		// this.syscodeService.i18n$
		// 	.pipe(tap(res => (this.langOption = res)))
		// 	.subscribe();
	}

	ngAfterViewInit() {
		if (this.countdown) {
			this.countdown.begin();
			this.intervalId = setInterval(() => {
				// 每1分鐘 檢測是否閒置
				this.checkIdle();
			}, this.checkPositionTime * 60000);
		} else {
			console.error('countdown is undefined.');
		}
	}

	ngOnDestroy() {
		// 清除間隔以防止內存洩漏
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
	}

	// - 檢測是否閒置
	checkIdle() {
		// 如果滑鼠位置相同 代表閒置
		if (
			this.mXBefore === this.mXCurrent &&
			this.mYBefore === this.mYCurrent
		) {
			this.start();
		}
		this.mXBefore = this.mXCurrent;
		this.mYBefore = this.mYCurrent;
	}

	// - 檢測滑鼠位置
	locatePosition(e) {
		this.mXCurrent = e.clientX;
		this.mYCurrent = e.clientY;
		this.pause();
	}

	// - 開始倒數
	start() {
		this.countdown.begin();
	}

	// - 暫停倒數
	pause() {
		if (this.countdown.left < 1200000) {
			this.countdown.restart();
			this.countdown.pause();
			return;
		}
		this.countdown.pause();
	}

	// - 倒數提醒與 dialog 跳出機制
	handleEvent(e: Event) {
		switch (e['action']) {
			case 'notify':
				this.reAsk();
				break;
			case 'done':
				this.confirmLogout();
				break;
		}
	}

	// - 將 menu 設置於上方，目前系統上不開放，以左側 menu tree 為主
	onMenuOnTop() {
		this.layoutService.onMenuOnTop();
	}

	// - 執行登出
	logout() {
		let input = {
			UserId: this.currentUser.UserId,
		};
		let user: User = { ...input };
		this.store.dispatch(new fromUser.LogoutAction(user));
	}

	// - 確認登出
	confirmLogout() {
		alert('登出倒數已結束，即將返回登入頁面');
		this.logout();
	}

	// - 是否要登出
	showPopup() {
		this.confirmationService.confirm({
			key: 'confirm',
			header: `是否登出`,
			message: `是否要登出 <span class='txt-color-teal'>${this.currentUser.UserName}</span> ?`,
			accept: () => {
				this.messageService.add({
					key: 'root',
					severity: 'success',
					summary: '已登出',
					detail: '謝謝您',
					life: 3000,
				});
				this.logout();
			},
			reject: () => {},
		});
	}

	// - 提醒即將結束倒數
	reAsk() {
		this.confirmationService.confirm({
			key: 'confirm',
			header: `提醒您`,
			message: `登出倒數即將結束，系統將在${
				this.notify / 60
			}分鐘後自動登出，是否要繼續使用？`,
			accept: () => {
				this.messageService.add({
					key: 'root',
					severity: 'success',
					summary: '請繼續使用',
					detail: '系統重新倒數',
					life: 3000,
				});
				this.countdown.restart(); // 重新倒數
			},
			reject: () => {
				this.logout();
			},
		});
	}

	// - 全螢幕
	onToggle() {
		var $body = $('body');
		var documentMethods = {
			enter: [
				'requestFullscreen',
				'mozRequestFullScreen',
				'webkitRequestFullscreen',
				'msRequestFullscreen',
			],
			exit: [
				'cancelFullScreen',
				'mozCancelFullScreen',
				'webkitCancelFullScreen',
				'msCancelFullScreen',
			],
		};

		if (!$body.hasClass('full-screen')) {
			this.fullScreenSign = 'fa-solid fa-compress fa-lg';
			$body.addClass('full-screen');
			document.documentElement[
				documentMethods.enter.filter(method => {
					return document.documentElement[method];
				})[0]
			]();
		} else {
			this.fullScreenSign = 'fa-solid fa-expand fa-lg';
			$body.removeClass('full-screen');
			document[
				documentMethods.exit.filter(method => {
					return document[method];
				})[0]
			]();
		}
	}

	// -  開啟問題回報 Dialog
	openReportDialog() {
		this.reportForm.reset();
		this.reportForm.patchValue({
			Menu: this.getFormattedPermission(
				this.router.url,
				this.currentUser.PermissionTree,
			),
		});
		this.reportFormModal = true;
	}

	// - 回報問題
	reportQues() {
		const param = {
			...this.reportForm.getRawValue(),
			CreateUserId: this.currentUser.UserId,
		};

		of(this.layoutService.setPageLoading(true))
			.pipe(
				switchMap(() => this.createUserReport$(param)),
				tap(() => (this.reportFormModal = false)),
				finalize(() => this.layoutService.setPageLoading(false)),
			)
			.subscribe();
	}

	// - 前往個人資訊頁
	gotoUserManagement() {
		this.router.navigate(['/System/UserManagement']);
	}

	// - 前往登入初始頁
	toHome() {
		this.router.navigate(['Bulletin']);
	}

	// - 設定語系
	setLanguage({ value }) {
		// this.i18nService.setLanguage(value);
	}

	// - 格式化 menu tree 資訊
	getFormattedPermission(url, tree) {
		const pathParts = url.split('/').filter(part => part !== '');
		const treeObj: { [key: string]: string } = {};
		function getMenuObj(menu) {
			treeObj[menu.Id] = menu.Name;
			_.forEach(menu?.Children, item => getMenuObj(item));
		}
		_.forEach(tree, item => getMenuObj(item));
		return treeObj[pathParts[pathParts.length - 1]];
	}

	// ? API Start
	createUserReport$(param): Observable<ResponseType> {
		return this.problemReportService.createUserReport(param).pipe(
			tap(res => {
				if (res.code === '000') {
					this.messageService.add({
						key: 'root',
						severity: 'success',
						summary: '回報成功',
						detail: '問題需求回報成功',
						icon: 'bounce animated',
						life: 3000,
					});
				}
			}),
		);
	}
}
