<header
    id="header"
    class="d-flex justify-content-between align-items-center"
    (document:mousemove)="locatePosition($event)">
    <div id="logo-group">
        <!-- PLACE YOUR LOGO HERE -->
        <div
            id="logo"
            class="d-flex">
            <!-- INFO: Mobile 三明治選單  -->
            <sa-collapse-menu></sa-collapse-menu>
            <a
                class="d-flex align-items-center"
                (click)="toHome()">
                <img
                    class="me-2"
                    alt="logo"
                    src="assets/img/web/Logo-Light-C.svg"
                    animate-visible="true"
                    animate-speed="0.5s" />
                <div class="logo-text d-none d-lg-block">
                    {{ environment.projectNameCH }}
                </div>
            </a>
        </div>
    </div>
    <!-- pulled right: nav area -->
    <div class="d-flex justify-content-start align-items-center">
        <!-- INFO: 登出倒數-->
        <div class="countdown d-none d-md-block mx-2">
            <countdown
                #countdown
                [config]="countdownConfig"
                (event)="handleEvent($any($event))"></countdown>
        </div>
        <!-- INFO: 使用者當前資訊-->
        <span
            class="user-box font-weight-bold d-none d-sm-block"
            *ngIf="currentUser$ | async as currentUser">
            <span class="ms-1">
                {{
                    currentUser?.DeptName
                        ? currentUser?.DeptName
                        : currentUser?.Dept
                        ? currentUser?.Dept
                        : '單位'
                }}
                ｜
            </span>
        </span>
        <div
            class="dropdown"
            *ngIf="currentUser$ | async as currentUser">
            <span class="user-box mx-2 font-weight-bold d-none d-sm-block">
                {{ currentUser.UserName }}
            </span>
        </div>
        <!-- INFO: 前往個人資訊 -->
        <a
            class="bg__action"
            data-action="userLogout"
            title="個人資訊"
            (click)="gotoUserManagement()">
            <em class="fa-regular fa-circle-user fa-lg"></em>
        </a>

        <!-- INFO: 語系切換 -->
        <!-- <a
            class="bg__action"
            data-action="userLogout"
            title="語系切換"
            (click)="op.toggle($event)">
            <em class="fa-solid fa-language fa-lg"></em>
        </a>
        <p-overlayPanel #op>
            <ng-template pTemplate>
                <div
                    class="p-2 px-3 pointer"
                    (click)="setLanguage({ value: langOption[0].value })">
                    {{ langOption[0].label }}
                </div>
                <div
                    class="p-2 px-3 pointer"
                    (click)="setLanguage({ value: langOption[1].value })">
                    {{ langOption[1].label }}
                </div>
            </ng-template>
        </p-overlayPanel> -->
        <!-- INFO: 問題回報:bg__report / fa fa-regular fa-question-circle fa-lg -->
        <a
            class="bg__action d-none d-md-block"
            title="問題回報"
            (click)="openReportDialog()">
            <em class="fa-regular fa-circle-question fa-lg"></em>
        </a>
        <!-- INFO: 全螢幕 -->
        <a
            class="bg__action d-none d-lg-block"
            title="全螢幕"
            (click)="onToggle()">
            <em [class]="fullScreenSign"></em>
        </a>
        <!-- INFO: 登出按鈕 -->
        <a
            class="bg__action"
            data-action="userLogout"
            title="登出"
            (click)="showPopup()">
            <em class="fa fa-sign-out fa-lg"></em>
        </a>
    </div>
</header>
<p-dialog
    header="問題回報"
    [(visible)]="reportFormModal"
    [style]="{ width: '600px' }"
    [modal]="true"
    [showHeader]="true"
    [draggable]="false"
    [closable]="true">
    <ng-template pTemplate="content">
        <form
            [formGroup]="reportForm"
            class="row">
            <div class="form-group d-flex col-12">
                <label class="col-form-label text-lg-end">目前功能</label>
                <div class="col pr-0">
                    <input
                        pInputText
                        formControlName="Menu"
                        [attr.disabled]="true ? '' : null"
                        type="text"
                        maxlength="60" />
                </div>
            </div>
            <div class="form-group d-flex col-12">
                <label class="col-form-label text-lg-end"
                    ><code>*</code> 回報類型</label
                >
                <div class="col pr-0">
                    <p-dropdown
                        formControlName="ProblemType"
                        placeholder="請選擇回報類型"
                        [options]="typeItems"></p-dropdown>
                </div>
            </div>
            <div class="form-group d-flex col-12">
                <label class="col-form-label text-lg-end"
                    ><code>*</code> 主旨</label
                >
                <div class="col pr-0">
                    <input
                        formControlName="Subject"
                        type="text"
                        placeholder="請輸入主旨"
                        pInputText
                        maxlength="60" />
                </div>
            </div>
            <div class="form-group d-flex col-12">
                <label class="col-form-label text-lg-end"
                    ><code>*</code> 內容</label
                >
                <div class="col pr-0">
                    <textarea
                        pInputTextarea
                        formControlName="ReportContent"
                        [rows]="5"
                        [cols]="30"
                        maxlength="150"
                        placeholder="請輸入問題內容(最多150字)"
                        [autoResize]="false"></textarea>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="d-flex justify-content-end align-items-center">
            <button
                pButton
                type="button"
                label="取消"
                class="p-button-cancel"
                (click)="reportFormModal = false"></button>
            <button
                pButton
                type="button"
                label="送出"
                class="p-button-primary ms-2"
                [disabled]="reportForm.invalid"
                (click)="reportQues()"></button>
        </div>
    </ng-template>
</p-dialog>
<p-confirmDialog
    #confirm
    key="confirm"
    [closable]="false"
    [style]="{ width: '700px' }">
    <p-footer>
        <button
            class="p-button-cancel"
            type="button"
            pButton
            label="取消"
            (click)="confirm.reject()"></button>
        <button
            class="p-button-primary ms-2"
            type="button"
            pButton
            label="確定"
            (click)="confirm.accept()"></button>
    </p-footer>
</p-confirmDialog>
<p-confirmPopup></p-confirmPopup>
