import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'sa-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styles: [],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet]
})
export class DashboardLayoutComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}
