import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

import { Observable } from 'rxjs';
import { ProblemReportService } from '@app/core/services';

/*-- service & models --*/
import { LayoutService } from '@app/core/services/layout.service';
import { User } from '@app/core/models';

/*-- Prime NG --*/
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
	selector: 'sa-big-breadcrumbs',
	template: `
		<!-- 麵包屑  Start ************************************-->
		<div
			class="d-flex justify-content-between align-items-baseline breadcrumb">
			<p-breadcrumb
				[model]="model"
				[home]="home"></p-breadcrumb>
		</div>
		<!-- 麵包屑  END ************************************-->
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [DialogService],
	standalone: true,
	imports: [BreadcrumbModule, ButtonModule],
})
export class BigBreadcrumbsComponent implements OnInit {
	currentUser$: Observable<User>;
	currentUser: User;

	@Input() public model;
	@Input() public home;

	ref: DynamicDialogRef;

	getPageTitle: string;

	// 問題回報
	reportFormModal = false;
	reportForm = this.fb.group({
		Menu: ['', Validators.required], // 當前頁面名稱
		Subject: ['', Validators.required], // 主旨
		ProblemType: ['', Validators.required], // 問題類型
		ReportContent: ['', Validators.required], // 內容
		CreateUserId: [''],
	});

	constructor(
		private store: Store<fromUser.UserState>,
		private fb: UntypedFormBuilder,
		public layoutService: LayoutService,
		public messageService: MessageService,
		public dialogService: DialogService,
		public problemReportService: ProblemReportService,
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}

	ngOnInit() {
		this.currentUser$.subscribe(v => {
			this.currentUser = v;
		});
		this.getPageTitle = this.model
			? this.model[this.model.length - 1].label
			: this.home.label;
	}
}
