import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { config } from "@app/core/app.config";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    constructor(private http: HttpClient) { }

    //取得資料來源的節點類型
    public getSourceNodeTypeList(): Observable<any> {
        return this.http.post(`${config.VisualizeUrl}/GetSourceNodeTypeList`, '');
    }

    //擴展
    public expend(param): Observable<any> {
        return this.http.post(`${config.VisualizeUrl}/Expend`, param);
    }

    //取得節點Detail
    public getNodeDetail(param): Observable<any> {
        return this.http.post(`${config.VisualizeUrl}/GetNodeDetail`, param);
    }

    //取得連結Detail
    public getLinkDetail(param): Observable<any> {
        return this.http.post(`${config.VisualizeUrl}/GetLinkDetail`, param);
    }
}
