import { environment } from '../../environments/environment';
const baseUrl: string = environment.baseUrl + 'api/';

export const config: any = {
	baseUrl: baseUrl,

	/** 使用者 */
	UserInfoUrl: baseUrl + 'UserInfo',
	/** 系統代碼 */
	SysCodeUrl: baseUrl + 'SysCode',
	/** 公告 */
	BillboardUrl: baseUrl + 'Bulletins',
	/** 權限角色 */
	RoleGroupUrl: baseUrl + 'RoleGroup',
	/** 圖形驗證 */
	AuthorizationUrl: baseUrl + 'Authorization',

	/** 範本功能設定 */
	TemplateSettingUrl: baseUrl + 'TemplateSetting',
	/** 訓練管理 */
	TrainingCaseUrl: baseUrl + 'TrainingCase',
	/** 演練案件 */
	MailSenderUrl: baseUrl + 'MailSender',
	/** 演練案件白名單 */
	WhiteListUrl: baseUrl + 'WhiteList',
	/** 資產 */
	AssetsUrl: baseUrl + 'Assets',
	/** 知識 */
	KnowledgeUrl: baseUrl + 'Knowledge',
	/** 關鍵字檢索 */
	SearchUrl: baseUrl + 'Search',
	/** Log紀錄 */
	LogAuditUrl: baseUrl + 'LogAudit',
	/** 問題回報功能 */
	ProblemReportUrl: baseUrl + 'ProblemReport',
	/** 視覺化 */
	VisualizeUrl: baseUrl + 'Visualize',
	/** 報表 */
	ReportUrl: baseUrl + 'Report',

	logoutUrl: baseUrl + 'auth/logout',
	reLoadSessionUrl: baseUrl + 'auth/reLoadSession',
	versionUrl: baseUrl + 'auth/version',
};
