<div id="hide-menu" class="pull-right">
	<span>
		<a
			(click)="onToggle()"
			title="縮小選單"
			style="
				width: 36px;
				height: 28px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				right: 0;
				top: 5rem;
				border-bottom: 1px solid #302f2f;
				background: #454545;
				color: #a8a8a8;
				text-align: center;
				padding: 0;
				border-radius: 5px 0 0 5px;
				cursor: pointer;
				margin-top: 7px;
				font-size: 19px;
				transition: all 0.1s linear 0s;
			"
		>
			<em class="fa fa-arrow-circle-left"></em>
		</a>
	</span>
</div>
