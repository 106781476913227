<aside id="left-panel">
    <sa-minify-menu></sa-minify-menu>
    <nav>
        <ul saSmartMenu>
            <ng-container *ngFor="let list of navList; let idx_1 = index">
                <li routerLinkActive="active">
                    <a
                        class="d-flex align-items-baseline"
                        [routerLink]="list.subList?.length ? null : list.path">
                        <em class="{{ list.icon }} me-2"></em>
                        <span
                            class="menu-item-parent"
                            [ngClass]="{
                                'menu-item-single': !list.subList?.length
                            }"
                            >{{ list.name }}</span
                        >
                    </a>
                    <ng-container>
                        <ul *ngIf="list.subList?.length">
                            <li
                                *ngFor="
                                    let sub of list.subList;
                                    let idx_2 = index
                                "
                                routerLinkActive="active">
                                <!-- INFO:layer2 -->
                                <a
                                    [routerLink]="
                                        sub.subList?.length ? null : sub.path
                                    ">
                                    <span class="menu-item-parent">
                                        {{ sub.name }}
                                    </span>
                                </a>
                                <ng-container *ngIf="sub.subList?.length">
                                    <ul>
                                        <li
                                            *ngFor="
                                                let sub of sub.subList;
                                                let idx_3 = index
                                            "
                                            routerLinkActive="active">
                                            <a
                                                *ngIf="!sub.subList?.length"
                                                [routerLink]="sub.path">
                                                <span class="menu-item-parent">
                                                    {{ sub.name }}
                                                </span>
                                            </a>
                                            <ng-container
                                                *ngIf="sub.subList?.length">
                                                <ul>
                                                    <li
                                                        *ngFor="
                                                            let sub of sub.subList;
                                                            let idx_4 = index
                                                        "
                                                        routerLinkActive="active">
                                                        <a
                                                            [routerLink]="
                                                                sub.subList
                                                                    ?.length
                                                                    ? null
                                                                    : sub.path
                                                            ">
                                                            <span
                                                                class="menu-item-parent">
                                                                {{
                                                                    sub.name
                                                                }}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                        </li>
                                    </ul>
                                </ng-container>
                            </li>
                        </ul>
                    </ng-container>
                </li>
            </ng-container>
        </ul>
    </nav>
</aside>
