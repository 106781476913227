import {Component, OnInit, Input} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: '[activitiesNotification]',
    templateUrl: './activities-notification.component.html',
    standalone: true,
    imports: [NgClass]
})
export class ActivitiesNotificationComponent implements OnInit {

  @Input() item: any;

  constructor() {}

  ngOnInit() {
  }

  setClasses(){
    let classes = {
      'fa fa-fw fa-2x':true
    };
    classes[this.item.icon] = true;
    return classes
  }

}
