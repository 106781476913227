import { Component } from '@angular/core';
import { LayoutService } from '@app/core/services';
import { Store } from '@ngrx/store';

@Component({
	selector: 'sa-minify-menu',
	template: `
		<span
			class="minifyme"
			(click)="toggle()"
			title="展開選單">
			<em class="fa fa-arrow-circle-left hit fa-xl"></em>
		</span>
	`,
	standalone: true,
})
export class MinifyMenuComponent {
	constructor(
		private store: Store<any>,
		private layoutService: LayoutService,
	) {}

	toggle() {
		this.layoutService.onMinifyMenu();
		this.layoutService.onCollapseMenu(false);
	}
}
