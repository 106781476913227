import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { LoginLayoutComponent } from '@app/shared/layout/app-layouts/login-layout.component';
import { DashboardLayoutComponent } from './shared/layout/app-layouts/dashboard-layout.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { environment } from '../environments/environment';

// 環境變數
const _environment = environment;

const routes: Routes = [
	{
		path: '',
		redirectTo: 'Web/Login',
		pathMatch: 'full',
	},
	{
		path: '',
		canActivate: [AuthGuard],
		canLoad: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: MainLayoutComponent,
		data: { pageTitle: 'Home' },
		children: [
			// {
			// 	path: 'Dashboard',
			// 	loadChildren: () =>
			// 		import('./features/dashboard/dashboard.module').then(
			// 			(m) => m.DashboardModule
			// 		),
			// 	data: {
			// 		pageTitle: 'Dashboard',
			// 	},
			// },
			{
				path: 'Bulletin',
				loadChildren: () =>
					import('./features/bulletin/bulletin.module').then(
						m => m.BulletinModule,
					),
				data: {
					pageTitle: 'Bulletin',
				},
			},
			{
				path: 'Case',
				loadChildren: () =>
					import('./features/case/case.module').then(
						m => m.CaseModule,
					),
				data: {
					pageTitle: 'Case',
				},
			},
			{
				path: 'Train',
				loadChildren: () =>
					import('./features/train/train.module').then(
						m => m.TrainModule,
					),
				data: {
					pageTitle: 'Train',
				},
			},
			{
				path: 'Template',
				loadChildren: () =>
					import('./features/template/template.module').then(
						m => m.TemplateModule,
					),
				data: {
					pageTitle: 'Template',
				},
			},
			{
				path: 'Analysis',
				loadChildren: () =>
					import('./features/analysis/analysis.module').then(
						m => m.AnalysisModule,
					),
				data: {
					pageTitle: 'Analysis',
				},
			},
			{
				path: 'StatisticalReport',
				loadChildren: () =>
					import(
						'./features/statistical-report/statistical-report.module'
					).then(m => m.StatisticalReportModule),
				data: {
					pageTitle: 'StatisticalReport',
				},
			},
			{
				path: 'System',
				loadChildren: () =>
					import('./features/system/system.module').then(
						m => m.SystemModule,
					),
				data: {
					pageTitle: 'System',
				},
			},
		],
	},
	{
		path: 'Web', //login 登入組件
		component: LoginLayoutComponent,
		loadChildren: () =>
			import('./features/web/web.module').then(m => m.WebModule),
	},
	// {
	// 	path: 'Miscellaneous', //error 500組件
	// 	component: ErrorPageLayoutComponent,
	// 	loadChildren: () =>
	// 		import('./features/miscellaneous/miscellaneous.module').then(
	// 			(m) => m.MiscellaneousModule
	// 		),
	// },
	{
		path: 'Dashboard', //儀錶板 組件
		component: DashboardLayoutComponent,
		loadChildren: () =>
			import('./features/dashboard/dashboard.module').then(
				m => m.DashboardModule,
			),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, _environment.urlUseHash)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
