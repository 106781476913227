import { Component, OnInit } from '@angular/core';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
	selector: 'sa-collapse-menu',
	templateUrl: './collapse-menu.component.html',
	standalone: true,
})
export class CollapseMenuComponent {
	constructor(private layoutService: LayoutService) {}

	ngOnInit() {
		this.init();
	}

	onToggle() {
		this.layoutService.onCollapseMenu();
		this.layoutService.onMinifyMenu();
	}

	init() {
		this.layoutService.initMinifyMenu();
	}
}
