import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';

@Component({
    selector: 'sa-footer',
    templateUrl: './footer.component.html',
    standalone: true
})
export class FooterComponent implements OnInit {
	environment = environment;
	constructor() {}

	ngOnInit() {
		// this.environment
	}
}
