import { NgModule } from '@angular/core';

// 一般及第三方匯入
import { GeneralAndThirdPartyModule } from '@app/shared/general-and-third-party.module';

// layout/I18n...
import { I18nModule } from '@app/shared/i18n/i18n.module';
// import { UserModule } from '@app/shared/user/user.module';
// import { VoiceControlModule } from '@app/shared/voice-control/voice-control.module';

// import { PipesModule } from '@app/shared/pipes/pipes.module';

import { SmartadminLayoutModule } from '@app/shared/layout';


// custom shared Module
import { DatepickerModule } from '@app/shared/datepicker/datepicker.module';

@NgModule({
	imports: [
    // 一般及第三方匯入
    GeneralAndThirdPartyModule,
    // layout/I18n...
    I18nModule,
    // PipesModule,
    SmartadminLayoutModule,
    // custom shared Module
    DatepickerModule
],
	exports: [
    // 一般及第三方匯入
    GeneralAndThirdPartyModule,
    // layout/I18n...
    I18nModule,
    // PipesModule,
    SmartadminLayoutModule,
    // custom shared Module
    DatepickerModule
],
})
export class SharedModule { }
