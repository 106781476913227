import { Component, OnInit } from '@angular/core';
import {LayoutService} from "@app/core/services/layout.service";
import { RouteBreadcrumbsComponent } from './route-breadcrumbs.component';

@Component({
    selector: 'sa-ribbon',
    templateUrl: './ribbon.component.html',
    standalone: true,
    imports: [RouteBreadcrumbsComponent]
})
export class RibbonComponent implements OnInit {

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
  }

  resetWidgets() {
    this.layoutService.factoryReset()
  }

}
