import { NgModule } from '@angular/core';
// 一般及第三方匯入
import { GeneralAndThirdPartyModule } from '@app/shared/general-and-third-party.module';
// 客製化時間選取元件
import { DatepickerMinmaxComponent } from './datepicker-minmax/datepicker-minmax.component';
// i
import { MonthpickerMinmaxComponent } from './monthpicker-minmax/monthpicker-minmax.component';
// import { BasicDatepickerOwlVersionComponent } from './basic-datepicker-owl-version/basic-datepicker-owl-version.component';
import { BasicDatepickerComponent } from './basic-datepicker/basic-datepicker.component';

@NgModule({
    imports: [GeneralAndThirdPartyModule, DatepickerMinmaxComponent,
        MonthpickerMinmaxComponent,
        BasicDatepickerComponent],
    exports: [
        DatepickerMinmaxComponent,
        MonthpickerMinmaxComponent,
        BasicDatepickerComponent,
        // BasicDatepickerOwlVersionComponent
    ]
})
export class DatepickerModule { }
