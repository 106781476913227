/** SmartAdmin既有Service */
import { StorageService } from './storage.service';
import { AuthTokenService } from './auth-token.service';
import { TokenInterceptor } from './token.interceptor';
import { MyHttpInterceptor } from './http.interceptor';
import { JsonApiService } from './json-api.service';
import { NotificationService } from './notification.service';
import { BodyService } from './body.service';
import { LayoutService } from './layout.service';
import { SoundService } from './sound.service';
import * as fromVoice from './voice';

/** 專案新增 */
import { UserService } from './user.service';
import { SyscodeService } from './syscode.service';
import { ExcelService } from './excel.service';
import { BillboardService } from './billboard.service';
import { RoleGroupService } from './rolegroup.service';
import { TemplateService } from './template.service';
import { TrainingService } from './training.service';
import { MailService } from './mail.service';
import { ReportService } from './report.service';
import { NetworkService } from './network.service';
import { LogAuditService } from './log-audit.service';
import { ProblemReportService } from './problem-report.service';

/** primeNg */
import { MessageService, ConfirmationService } from 'primeng/api';



export const services = [
	/** SmartAdmin既有Service */
	StorageService,
	AuthTokenService,
	TokenInterceptor,
	MyHttpInterceptor,
	JsonApiService,
	NotificationService,
	BodyService,
	LayoutService,
	SoundService,
	fromVoice.VoiceControlService,
	fromVoice.VoiceRecognitionService,
	/** 專案新增 */
	SyscodeService,
	TrainingService,
	ExcelService,
	BillboardService,
	RoleGroupService,
	TemplateService,
	MailService,
	UserService,
	ReportService,
	NetworkService,
	LogAuditService,
	ProblemReportService,
	/** primeNg */
	MessageService,
	ConfirmationService,
];

export * from './storage.service';
export * from './auth-token.service';
export * from './token.interceptor';
export * from './json-api.service';
export * from './notification.service';
export * from './body.service';
export * from './layout.service';
export * from './sound.service';
export * from './voice';
export * from './user.service';
export * from './syscode.service';
export * from './excel.service';
export * from './billboard.service';
export * from './rolegroup.service';
export * from './template.service';
export * from './training.service';
export * from './mail.service';
export * from './report.service';
export * from './network.service';
export * from './log-audit.service';
export * from './problem-report.service';

export * from 'primeng/api';
