<div id="extr-page">
    <div
        id="main"
        role="main"
        class="animated login">
        <div class="login__box">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
