import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { config } from '@app/core/app.config';

@Injectable({
	providedIn: 'root',
})
export class ReportService {
	constructor(
		private http: HttpClient,
		private httpService: HttpClientService
	) { }

	/** 操作行為時段分析 */
	public getRoutine(param): Observable<any> {
		return this.httpService.httpPost(
			`${config.ReportUrl}/GetRoutine`,
			param
		);
	}

	/** 該時段總計的操作行為明細 */
	public getRoutineDetail(param): Observable<any> {
		return this.httpService.httpPost(
			`${config.ReportUrl}/GetRoutineDetail`,
			param
		);
	}

	/** 範本類型點擊分析 */
	public getRoutineType(param): Observable<any> {
		return this.httpService.httpPost(
			`${config.ReportUrl}/GetRoutineType`,
			param
		);
	}

	/** 範本使用率 */
	public getTypeUsing(param): Observable<any> {
		return this.httpService.httpPost(
			`${config.ReportUrl}/GetTypeUsing`,
			param
		);
	}

	/** 操作行為時段分析Excel */
	public getRoutineExcel(param): Observable<any> {
		return this.httpService.httpExport(
			`${config.ReportUrl}/GetRoutineExcel`,
			param
		);
	}

	/** 範本類型點擊分析Excel */
	public getRoutineTypeExcel(param): Observable<any> {
		return this.httpService.httpExport(
			`${config.ReportUrl}/GetRoutineTypeExcel`,
			param
		);
	}

	/** 範本使用率Excel */
	public getTypeUsingExcel(param): Observable<any> {
		return this.httpService.httpExport(
			`${config.ReportUrl}/GetTypeUsingExcel`,
			param
		);
	}

	/** 單位部室主題點擊率 */
	public getDeptType(param): Observable<any> {
		return this.httpService.httpPost(
			`${config.ReportUrl}/GetDeptType`,
			param
		);
	}

	/** 單位部室點擊分析 */
	public getDeptClickType(param): Observable<any> {
		return this.httpService.httpPost(
			`${config.ReportUrl}/GetDeptClickType`,
			param
		);
	}

	/** 單位部室下拉選單 */
	public getDeptDDL(): Observable<any> {
		return this.httpService.httpPost(
			`${config.ReportUrl}/GetDeptDDL`,
			{}
		);
	}

	/** 單位部室點擊分析Excel */
	public getDeptClickTypeExcel(param): Observable<any> {
		return this.httpService.httpExport(
			`${config.ReportUrl}/GetDeptClickTypeExcel`,
			param
		);
	}

	/** 單位部室主題點擊率Excel */
	public getDeptTypeExcel(param): Observable<any> {
		return this.httpService.httpExport(
			`${config.ReportUrl}/GetDeptTypeExcel`,
			param
		);
	}

	/** 演練進度列表中的報表下載 */
	public getTaskClickTypeExcel(param): Observable<any> {
		return this.httpService.httpExport(
			`${config.ReportUrl}/GetTaskClickTypeExcel`,
			param
		);
	}
}
