import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDatepicker,
  MatDatepickerInputEvent,
} from "@angular/material/datepicker";
import * as moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { CalendarModule } from "primeng/calendar";
import { NgIf } from "@angular/common";

@Component({
    selector: "sa-monthpicker-minmax",
    templateUrl: "./monthpicker-minmax.component.html",
    styleUrls: ["./monthpicker-minmax.component.scss"],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, CalendarModule]
})
export class MonthpickerMinmaxComponent {
  @Input() ctrlStart = new FormControl();
  @Input() ctrlEnd = new FormControl();
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() must = false;
  @Input() minDate: Date;
  @Input() maxDate: Date;

  /** 預設時間 */
  initStartDate;
  initEndDate;

  tw: any;

  constructor() {
 this.tw = {
		firstDayOfWeek: 0,
		dayNames: ['日', 'ㄧ', '二', '三', '四', '五', '六'],
		dayNamesShort: ['日', 'ㄧ', '二', '三', '四', '五', '六'],
		dayNamesMin: ['日', 'ㄧ', '二', '三', '四', '五', '六'],
		monthNames: [
			'一月',
			'二月',
			'三月',
			'四月',
			'五月',
			'六月',
			'七月',
			'八月',
			'九月',
			'十月',
			'十一月',
			'十二月',
		],
		monthNamesShort: [
			'一月',
			'二月',
			'三月',
			'四月',
			'五月',
			'六月',
			'七月',
			'八月',
			'九月',
			'十月',
			'十一月',
			'十二月',
		],
		today: '確認',
		clear: '清除',
		dateFormat: 'mm-yy',
		weekHeader: 'Wk',
 };
  }

  addEvent(event, formGroup: FormGroup, formControlName) {
    this.minDate = new Date(event);
    this.minDate.setMonth(new Date(Date.parse(event)).getMonth());
    this.minDate.setFullYear(new Date(Date.parse(event)).getFullYear());
  }

  /** 選取開始時間 */
  onSelectStart(event, formGroup: FormGroup, formControlName) {
    const d = new Date(Date.parse(event));
    const patchValue: object = {};
    patchValue[formControlName] = moment(d)
      .startOf("date")
      .format("YYYY-MM-DD HH:mm:ss");
    formGroup.patchValue(patchValue);
  }

  /** 選取結束時間 */
  onSelectEnd(event, formGroup: FormGroup, formControlName) {
    const d = new Date(Date.parse(event));
    const patchValue: object = {};
    patchValue[formControlName] = moment(d)
      .endOf("date")
      .format("YYYY-MM-DD HH:mm:ss");
    formGroup.patchValue(patchValue);
  }

  /** 清除時間 */
  clearTime() {
    this.initStartDate = "";
    this.initEndDate = "";
  }
}
