import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, throwError } from "rxjs";
import { config } from "@app/core/app.config";
import { environment } from "../../../environments/environment";
import { HttpClientService } from "@app/core/services/HttpClientService";

@Injectable({
    providedIn: "root",
})
export class ProblemReportService {
    constructor(private httpService: HttpClientService) { }

    /** 新增問題回報 */
    public createUserReport(dataSrc: any): Observable<any> {
        return this.httpService.httpPost(
            `${config.ProblemReportUrl}/CreateUserReport`,
            dataSrc
        );
    }

    /** 取得問題回報管理清單 */
    public getProblemReportList(dataSrc: any): Observable<any> {
        return this.httpService.httpPost(
            `${config.ProblemReportUrl}/GetProblemReportList`,
            dataSrc
        );
    }

    /** 回覆問題回報 */
    public replyReport(dataSrc: any): Observable<any> {
        return this.httpService.httpPost(
            `${config.ProblemReportUrl}/ReplyReport`,
            dataSrc
        );
    }

    /** 作廢問題回報 */
    public invalidReport(dataSrc: any): Observable<any> {
        return this.httpService.httpPost(
            `${config.ProblemReportUrl}/InvalidReport`,
            dataSrc
        );
    }

}
