import { JsonApiService } from '@app/core/services/json-api.service';
import { Injectable, OnInit } from '@angular/core';
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanLoad,
	Route,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of, BehaviorSubject } from 'rxjs/index';
import { map, take, tap } from 'rxjs/operators';

import * as fromUser from '@app/core/store/user';
import { MessageService, UserService } from '@app/core/services';
import { User } from '../models';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
	loginStatus$: Observable<boolean>;
	currentUser$: Observable<User>;
	currentUser: User;

	constructor(
		private store: Store<fromUser.UserState>,
		private router: Router,
		private messageService: MessageService,
	) {
		this.loginStatus$ = store.select(fromUser.getIsLogin);
		this.currentUser$ = store.select(fromUser.getCurrentUser);
		this.currentUser$.subscribe(v => {
			this.currentUser = v;
		});
	}

	/** 決定是否可進入目標路由  */
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		const url: string = state.url;
		// console.log('canActivate', url);
		return this.checkLogin(url);
	}

	/** 決定是否可進入目標路由之子路由  */
	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean> | Promise<boolean> | boolean {
		const url: string = state.url;
		console.log('canActivateChild', url);
		return this.checkUrlPermission(url);
	}

	/** 決定這個路由模組是否可被載入 (延遲或預先載入模組的權限) */
	canLoad(route: Route): Observable<boolean> {
		const url = `/${route.path}`;
		console.log('canLoad:', url);
		return this.checkUrlPermission(url);
	}

	/**
	 * 檢查登入狀態
	 * @param url 當前url
	 * @returns
	 */
	checkLogin(url: string): Observable<boolean> {
		return this.loginStatus$.pipe(
			tap(async status => {
				console.log('loginStatus登入狀態:', status);
				// 登入狀態 = false
				if (!status) {
					// alert('無權限使用，將前往登入頁');
					this.messageService.add({
						severity: 'error',
						summary: '無權限使用',
						detail: '將前往登入頁',
						life: 3000,
						key: 'root',
					});
					this.router.navigate(['Web/Login']);
				}

				// 登入狀態 = true
				if (status) {
					// 檢查權限
					this.checkUrlPermission(url);
				}
			}),
			take(1),
		);
	}

	/**
	 * 檢查登入狀態
	 * @param url 當前url
	 * @returns
	 */
	checkUrlPermission(url: string): Observable<boolean> {
		const targetUrl = url.split('/').pop().split(';')[0];
		console.log('targetUrl: ', targetUrl);
		// FIXME:待修改，等後端回傳Permission
		// return of(true);
		// if (
		// 	this.currentUser.Permission.filter((permission) => {
		// 		let regexpUrl = new RegExp(permission);
		// 		console.log('regexpUrl:', regexpUrl);
		// 		return regexpUrl.test(targetUrl);
		// 	}).length > 0
		// ) {
		// 	return of(true);
		// } else {
		// 	this.messageService.add({
		// 		severity: 'error',
		// 		summary: '無權限訪問該頁面',
		// 		detail: '請聯絡系統管理員',
		// 		life: 3000,
		// 	});
		// 	return of(false);
		// }
		if (
			(this.currentUser.Permission &&
				this.currentUser.Permission.some(
					permission => permission === targetUrl,
				)) ||
			targetUrl === 'Bulletin'
		) {
			return of(true);
		} else {
			// INFO: 確認 currentUser.Permission 是不是null
			this.messageService.add({
				severity: 'error',
				summary: '無權限訪問該頁面',
				detail: '請聯絡系統管理員',
				life: 3000,
				key: 'root',
			});
			return of(false);
		}
	}
}
