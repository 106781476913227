import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { config } from '@app/core/app.config';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {

  constructor(
    private httpService: HttpClientService
  ) { }

  /** 新增範本類型 */
  public createTemplateType(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/CreateTemplateType`, param);
  }

  /** 編輯範本類型 */
  public editTemplateType(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/EditTemplateType`, param);
  }

  /** 檢查該範本類型是否已有設定MAIL範本 */
  public checkTemplateContent(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/CheckTemplateContent`, param);
  }

  /** 檢查是否已有設定相同MAIL TITLE */
  public checkTemplateTitle(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/CheckTemplateTitle`, param);
  }

  /** 刪除範本項目 */
  public deleteTemplateType(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/DeleteTemplateType`, param);
  }

  /** 取得範本類型清單 */
  public getTemplateType(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/GetTemplateType`, param);
  }

  /** 範本主題下拉選單 */
  public getDropdownTemplateType(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/GetDropdownTemplateType`, param);
  }

  /** 新增信件範本內容 */
  public createMailTemplate(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/CreateMailTemplate`, param);
  }

  /** 編輯信件範本內容 */
  public editMailTemplate(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/EditMailTemplate`, param);
  }

  /** 刪除信件範本 */
  public deleteMailTemplate(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/DeleteMailTemplate`, param);
  }

  /** 取得信件範本列表 */
  public getMailTemplateList(param): Observable<any> {
    return this.httpService.httpPost(`${config.TemplateSettingUrl}/GetMailTemplateList`, param);
  }

}
