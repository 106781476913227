import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotifyActions, NotifyActionTypes } from './notify.actions';
import { map } from 'rxjs/operators';
import { NotifyService } from './notify.service';

@Injectable()
export class NotifyEffects {

  
  showError$ = createEffect(() => this.actions$.pipe(
    ofType(NotifyActionTypes.ShowError),
    map((_: any) => ({ code: _.payload.code, message: _.payload.message })),
    map(_ => this.notifyService.showError(_))
  ), { dispatch: false });

  
  snakNotify$ = createEffect(() => this.actions$.pipe(
    ofType(NotifyActionTypes.SnackNotify),
    map((_: any) => _.payload),
    map(_ => this.notifyService.snackNotify(_))
  ), { dispatch: false });

  constructor(private actions$: Actions, private notifyService: NotifyService) {}
  


}
