import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { config } from '@app/core/app.config';

@Injectable({
  providedIn: 'root',
})
export class MailService {

  constructor(
    private httpService: HttpClientService
  ) { }

  /** 新增演練 */
  public createMailTask(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/CreateMailTask`,
      param
    );
  }

  /** 刪除演練進度 */
  public deleteMailTask(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/DeleteMailTask`,
      param
    );
  }

  /** 接收收件人檔案 */
  public generateReceiveList(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/GenerateReceiveList`,
      param
    );
  }

  /**  取得演練進度 */
  public getMailTaskList(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/GetMailTaskList`,
      param
    );
  }

  /** 取得演練明細 */
  public getMailTaskDetail(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/GetMailTaskDetail`,
      param
    );
  }

  /** 取得信件讀取明細 */
  public getMailReadRecord(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/GetMailReadRecord`,
      param
    );
  }

  /** 取得信件連結點擊明細 */
  public getMailUrlRecord(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/GetMailUrlRecord`,
      param
    );
  }

  /** 取得信件附件開啟明細 */
  public getMailAttachRecord(param): Observable<any> {
    return this.httpService.httpPost(
      `${config.MailSenderUrl}/GetMailAttachRecord`,
      param
    );
  }

}
