<span class="padding-10">
	<em
		class="badge padding-5 no-border-radius bg-color-blueLight pull-left margin-right-5"
	>
		<em [ngClass]="setClasses()"></em>
	</em>
	<span>
		{{ item.message }}
		<br />
		<span class="pull-right font-xs text-muted">
			<em>{{ item.time }}</em>
		</span>
	</span>
</span>
